import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Form, Input, InputNumber, Space} from "antd";
import Breadcrumbs from "../../components/Breadcrumbs";
import MinusSVG from "../../icons/MinusSVG";
import Plus2SVG from "../../icons/Plus2SVG";
import Close2SVG from "../../icons/Close2SVG";
import {CartType, ProductType, UserType} from "../../utils/types";
import {useSelector} from "react-redux";
import store from "../../redux/store";
import {
    getCartAction,
    getLocalCartItemsAction,
    initLocalStorageAction,
    makeOrderAction,
    makeUnauthorizedOrderAction,
    putCartAction,
    remCartAction
} from "../../redux/actions/pageActions";
import History from "../../utils/history";
import {Link} from "react-router-dom";
import no_img from "../../images/no_img.png"
import SimpleModal from "../../components/SimpleModal";
import {MaskedInput} from "antd-mask-input";
import ForOrderSVG from "../../images/ForOrderSVG";

const CartItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
`;

const StyledCartItem = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #D7D8D3;
    padding: 20px 0 20px 20px;
    justify-content: space-between;

    span {
        display: flex;
        align-items: center;
    }
`;

const Photo = styled.img`
    width: 94px;
    height: 94px;
    object-fit: contain;
    margin-right: 12px;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    max-width: 315px;
    margin-right: 55px;
    color: #000000;
`;

const Article = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;


    color: #8A8A8A;
`;

const Price = styled.div`
    margin-right: 34px;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin-left: auto;

    color: #5B77CB;
`;

const Count = styled.div`
    display: flex;
    align-items: center;
    background: #F8F8F9;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    padding: 4px;
    letter-spacing: 0.005em;

    color: #151515;

    .ant-input-number {
        font-size: 18px;
        line-height: 150%;
        border: none;
        background: none;
        box-shadow: none;
        width: 34px;

        input {
            color: #151515;
            padding: 0;
            font-weight: 500;
            text-align: center;
        }
    }

    span {
        user-select: none;
        min-width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.plus, &.minus {
            cursor: pointer;

            &:hover {
                background: #eaeaea;
            }
        }
    }
`;

const Actions = styled.div`
    display: flex;
`;

const Action = styled.div`
    display: flex;
    cursor: pointer;
`;

const Summary = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #D7D8D3;
    padding: 36px 36px 45px 36px;
    width: 390px;
    margin-left: auto;
    min-width: 390px;

    p {
        color: #898989;
        font-size: 18px;
        margin-bottom: 28px;
    }

    h1 {
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        margin-bottom: 25px;

        color: #000000;
    }
`;

const Sum = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 14px;
    color: #898989;
`;

const Discount = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 14px;

    color: #E33434;
`;

const Delivery = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    padding-bottom: 24px;
    border-bottom: 1px solid #DCDCDC;
    margin-bottom: 24px;
    color: #898989;
`;

const Total = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 32px;
    color: #000000;
`;

const Content = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 25px;

    
`;

const StyledCartPage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 36px 18.75% 150px 18.75%;

    .ant-modal {
        width: 560px !important;
        top: 20%;

        .ant-input {
            border-radius: 4px;
            font-size: 18px;
            padding: 16px 24px;
            line-height: 18px;
        }

        .ant-modal-content {
            padding: 78px 108px;
        }

        h1 {
            font-size: 28px;
            color: #311c1c;
            font-weight: 600;
            margin-bottom: 40px;
        }

        .ant-modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .ant-form-item-control-input-content {

            &:last-child {
                justify-content: center;
            }
        }

        .ant-form {
            display: flex;
            flex-direction: column;
            max-width: 350px;
            width: 100%;
            align-items: center;

            label {
                font-size: 18px;
                color: #8a8a8a;
            }

            .ant-form-item {
                display: flex;
                margin-bottom: 0;
                width: 100%;

                .ant-row {
                    width: 100%;
                }
            }
        }

        .ant-form-item-explain-error {
            text-align: start !important;
        }

        .ant-form-item-control-input-content {
            display: flex;
        }

        .ant-btn {
            width: 172px;
            margin-top: 45px;
        }
    }

    .ant-space {
        .ant-btn {
            width: 100%;
            white-space: pre-wrap;

            &.second {
                border: 1px solid #5B77CB;
                background: white;

                span {
                    color: #5B77CB;
                }
            }
        }
    }

    @media screen and (max-width: 1919px) {
        padding: 36px 20px 150px 20px;
        ${Content} {
            flex-direction: column;

            > .ant-space {
                width: 100%;

                .ant-space-item {
                    width: 100%;
                }
            }

            ${Summary} {
                min-width: auto;
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 999px) {
        ${StyledCartItem} {
            align-items: flex-start;
            flex-direction: column;
            gap: 24px;

            ${Actions} {
                margin-left: 15px;
            }
        }
    }

    .ant-btn {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        background: #5B77CB;
        border: none;
        height: auto;
        padding: 15px;
        border-radius: 4px;

        span {
            color: #FFFFFF;
        }
    }
`;

function CartItem({Id, count, Code, Name, NewPrice, ImageUrls, ImageMain}: ProductType & { count: number }) {
    const user: UserType = useSelector((state: any) => state.loginReducer.userData);
    const [localCount, setLocalCount] = useState<number | null>(count)

    useEffect(() => {
        setLocalCount(count);
    }, [count])

    const handlePlus = useCallback(() => {
        if (count < 999)
            store.dispatch(putCartAction(user, [Id], true))
    }, [count, user, Id])

    const handleMinus = () => {
        store.dispatch(remCartAction(user, [Id]))
    }

    const removePosition = useCallback(() => {
        store.dispatch(remCartAction(user, [Id], count))
    }, [Id, count, user])

    const changeCountPosition = useCallback(() => {
        if (localCount !== null) {
            const value = localCount - count;
            if (localCount <= 0)
                return setLocalCount(count)

            if (value > 0)
                store.dispatch(putCartAction(user, [Id], true, value))
            else if (value < 0)
                store.dispatch(remCartAction(user, [Id], Math.abs(value)))
        }
    }, [Id, user, count, localCount])

    // const removePosition = (Id: number) => {
    //
    // }

    return (
        <StyledCartItem>
            <span>
                <Photo
                    src={ImageUrls?.[ImageMain] ? `/${ImageUrls?.[ImageMain]?.replace("//", "/")}` : no_img}/>
                <Info>
                    <Title>{Name}</Title>
                    <Article>{Code}</Article>
                </Info>
            </span>
            <span>
                <Price>
                                           {NewPrice ? <>
                                                   {NewPrice} ₽
                                               </> :
                                               <Space style={{
                                                   alignItems: "center",
                                                   color: "#7d7d7d",
                                                   display: "flex",
                                               }}
                                                      size={0}><ForOrderSVG style={{width: 40}}/>Под
                                                   заказ</Space>}


                </Price>
            <Count>
                <span onClick={handleMinus} className={"minus"}>
                    <MinusSVG/>
                </span>
                <InputNumber
                    max={999}
                    maxLength={3}
                    controls={false}
                    value={localCount}
                    onPressEnter={changeCountPosition}
                    onChange={(value) => setLocalCount(value)}
                    onBlur={changeCountPosition}
                />
                <span onClick={handlePlus} className={"plus"}>
                    <Plus2SVG/>
                </span>
            </Count>
            <Actions>
                <Action onClick={removePosition}><Close2SVG/></Action>
            </Actions>
         </span>
        </StyledCartItem>)

}

function CartPage() {
    const serverCart: CartType = useSelector((state: any) => state.pageReducer.cart);
    const localCart: { Id: number, Count: number }[] = useSelector((state: any) => state.pageReducer.localCart);
    const user: UserType = useSelector((state: any) => state.loginReducer.userData);
    const localCartItems: ProductType[] = useSelector((state: any) => state.pageReducer.localCartItems);

    const [unauthorizedModalOpen, setUnauthorizedModalOpen] = useState<boolean>(false);

    const [successfulModalOpen, setSuccessfulModalOpen] = useState<boolean>(false)

    const [cart, setCart] = useState<CartType>({CreatedDate: "", Positions: [], Status: undefined, UpdatedDate: ""})

    const [unauthorizedForm] = Form.useForm();

    const [unauthorizedLoading, setUnauthorizedLoading] = useState<boolean>(false)

    useEffect(() => {
        store.dispatch(getLocalCartItemsAction(localCart.map(el => el.Id)))
    }, [localCart])

    useEffect(() => {
        setCart(user?.Login ? serverCart : {
            CreatedDate: "",
            Status: undefined,
            UpdatedDate: "",
            Positions: localCart.map((el: any) => {
                return {...el, Item: localCartItems.find(localEl => localEl.Id === el.Id)}
            })
        })
    }, [user, localCartItems, serverCart, localCart])

    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    useEffect(() => {
        if (user?.Login)
            store.dispatch(getCartAction())
        else
            store.dispatch(getLocalCartItemsAction(localCart.map(el => el.Id)))
    }, [])

    return (
        <StyledCartPage>
            <SimpleModal open={successfulModalOpen} onCancel={() => setSuccessfulModalOpen(false)}>
                Ваш заказ успешно оформлен, ожидайте звонка нашего менеджера
            </SimpleModal>
            <SimpleModal open={unauthorizedModalOpen}
                         onCancel={() => setUnauthorizedModalOpen(false)}
                         getContainer={() => document.querySelector(`.${StyledCartPage.styledComponentId}`)!}>
                <h1>Оформление заказа</h1>
                <Form form={unauthorizedForm} onFinish={(fields) => {
                    setUnauthorizedLoading(true);
                    store.dispatch(makeUnauthorizedOrderAction({
                        Phone: `${fields.phoneZone}${fields.phoneNumber}`.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", ""),
                        Email: fields.Email
                    })).then(() => {
                        localStorage.removeItem("Cart");
                        store.dispatch(initLocalStorageAction())
                        setUnauthorizedModalOpen(false)
                        unauthorizedForm.resetFields()
                        setSuccessfulModalOpen(true)
                    }).finally(() => {
                        setUnauthorizedLoading(false)
                    })
                }} requiredMark={false} layout={"vertical"}>
                    <Form.Item label={"Номер телефона:"} style={{marginBottom: 28}}>
                        <Form.Item initialValue={'+7'} name={"phoneZone"}
                                   rules={[
                                       {
                                           required: true,
                                           message: "Поле не может быть пустым!"
                                       },
                                       {
                                           validator: (_, value) => {
                                               if (value !== undefined && value.indexOf("_") !== -1) {
                                                   return Promise.reject(new Error("Поле не может быть пустым!"))
                                               } else {
                                                   return Promise.resolve()
                                               }
                                           }
                                       }]}
                                   style={{display: 'inline-block', width: 140}}>
                            <MaskedInput mask={'+0[0][0]'}/>
                        </Form.Item>
                        <Form.Item initialValue={"(___) ___ __ __"} name={"phoneNumber"}
                                   rules={[
                                       {
                                           required: true,
                                           message: "Поле не может быть пустым!"
                                       },
                                       {
                                           validator: (_, value) => {
                                               if (value !== undefined && value.indexOf("_") !== -1) {
                                                   return Promise.reject(new Error("Поле должно быть заполнено полностью!"))
                                               } else {
                                                   return Promise.resolve()
                                               }
                                           }
                                       }]}
                                   style={{display: 'inline-block', margin: '0 0 0 8px'}}>
                            <MaskedInput mask={'(000) 000 00 00'}/>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name={"Email"} label={"Электронная почта:"} rules={[{
                        required: true,
                        message: "Поле является обязательным для заполнения!"
                    }, {
                        type: "email",
                        message: "Формат почты введен не верно!"
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item style={{justifyContent: "center"}}>
                        <Button loading={unauthorizedLoading} htmlType={"submit"}>Подтвердить</Button>
                    </Form.Item>
                </Form>
            </SimpleModal>
            <Breadcrumbs/>
            {cart?.Positions?.length > 0 ? <Content>
                <CartItems>
                    {cart?.Positions?.sort((el, el2) => {
                        return new Date(el2.CreatedDate).getTime() - new Date(el.CreatedDate).getTime();
                    })?.map(el => <CartItem key={el.Id} count={el.Count} {...el.Item}/>)}
                </CartItems>
                <Space style={{display: "flex", flexDirection: "column"}}>
                    <Summary>
                        <>
                            <h1>Сумма заказа:</h1>
                            <Sum>Сумма
                                заказа: <span>{formatter.format(cart?.Positions?.reduce((previousValue, currentValue) => {
                                    return previousValue + (currentValue?.Item?.OldPrice === 0 ? currentValue?.Item?.NewPrice * currentValue?.Count : currentValue?.Item?.OldPrice * currentValue?.Count)
                                }, 0) || 0)}</span></Sum>
                            <Discount>Скидка: <span>-{formatter.format(cart?.Positions?.reduce((previousValue, currentValue) => {
                                return previousValue + (currentValue?.Item?.OldPrice === 0 ? currentValue?.Item?.NewPrice * currentValue?.Count : currentValue?.Item?.OldPrice * currentValue?.Count)
                            }, 0) - cart?.Positions?.reduce((previousValue, currentValue) => {
                                return previousValue + currentValue?.Item?.NewPrice * currentValue?.Count
                            }, 0) || 0)}</span></Discount>
                            <Delivery>Доставка: <span>Бесплатно</span></Delivery>

                            <Total>К
                                оплате: <span>{formatter.format(cart?.Positions?.reduce((previousValue, currentValue) => {
                                    return previousValue + currentValue?.Item?.NewPrice * currentValue?.Count
                                }, 0) || 0)}</span></Total>
                            <Space size={12} direction={"vertical"}>
                                <Button onClick={() => {
                                    store.dispatch(makeOrderAction()).then(() => {
                                        History.push("/orders?modal=true")
                                    })
                                }}>Оплатить</Button>
                                {!user?.Login &&
                                    <Button disabled={!localCart?.length} className={"second"} onClick={() => {
                                        setUnauthorizedModalOpen(true)
                                    }}>Оформить без регистрации</Button>}
                            </Space>
                        </>
                    </Summary>
                    {cart?.Positions?.some(el => el?.Item?.NewPrice == 0) && <Summary>
                        <>
                            <h1>Сумма заказа:</h1>
                            <p>В данном заказе имеются позиции
                                "Под заказ". Конечная стоимость
                                будет рассчитана менеджером после
                                обработки всего заказа.
                            </p>
                            <Button onClick={() => {
                                store.dispatch(makeOrderAction()).then(() => {
                                    History.push("/orders?modal=true")
                                })
                            }}>Заказать</Button>
                        </>
                    </Summary>}
                </Space>
            </Content> : <Space style={{marginTop: 34}} size={50} direction={"vertical"}>
                <h1 style={{margin: 0}}>Ваша корзина пуста</h1>
                <Link to={"/catalog"}><Button>В каталог</Button></Link>
            </Space>}
        </StyledCartPage>
    );
}

export default CartPage;