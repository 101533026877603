import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ProductCard, {StyledProductCard} from "../../components/ProductCard";
import {Button} from "antd";
import {StyledCheckbox} from "../../components/StyledCheckbox";
import Breadcrumbs from "../../components/Breadcrumbs";
import {ProductType, UserType} from "../../utils/types";
import {useSelector} from "react-redux";
import store from "../../redux/store";
import {addFavoriteAction, getFavoritesAction, putCartAction} from "../../redux/actions/pageActions";
import {declension} from "../../utils/Declension";
import {mobileSizeRaw} from "../../config";

const Products = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 65px;
  gap: 25px;

  .ant-btn {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;

    color: #FFFFFF;
    background: #5B77CB;
    border-radius: 4px;
    border: none;
    padding: 18px 40px;
    height: auto;

    &:hover {
      color: #FFFFFF;
      opacity: .8;
    }

    &.cancel {
      color: #000000;
      background: #fff;
      border: 1px solid #494949;
      border-radius: 4px;

      &:hover {
        color: #000000;
        opacity: .8;
      }
    }
  }
`;

const Top = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;

  .ant-checkbox-wrapper {
    flex-direction: row-reverse;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    line-height: 40px;
    color: #000000;

    span {
      top: 2px;
    }
  }
`;

const Count = styled.div`
  display: flex;
  gap: 14px;

  h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    margin: 0;

    color: #311C1C;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;

    color: #8A8A8A;
  }
`;

const StyledFavoritesPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px 18.75% 135px 18.75%;

  @media screen and (max-width: 1919px) {
    padding: 65px 20px 135px 20px;
  }
  @media screen and (max-width: 999px) {
    ${StyledProductCard} {
      max-width: 210px;

      img {
        width: 112px;
      }

      .title {
        font-size: 16px;
      }
    }
  }
  @media screen and (${mobileSizeRaw}) {
    ${Top} {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .ant-checkbox-wrapper {
        > span {
          padding-left: 0;
        }
      }
    }

    ${Actions} {
      flex-direction: column;
    }

    ${StyledProductCard} {
      max-width: 320px;

      img {
        width: 206px;
      }

      .title {
        font-size: 18px;
      }
    }
  }
  @media screen and (max-width: 360px) {
    ${StyledProductCard} {
      img {
        width: 112px;
      }
    }
  }
`;

function FavoritesPage() {
    const favorites: ProductType[] = useSelector((state: any) => state.pageReducer.favorites);
    const user: UserType = useSelector((state: any) => state.loginReducer.userData);
    const localFavorites: number[] = useSelector((state: any) => state.pageReducer.localFavorites);

    const [selected, setSelected] = useState<number[]>([]);

    const handleDelete = () => {
        store.dispatch(addFavoriteAction(user, selected))
        setSelected([]);
    }
    const handleCart = () => {
        selected.forEach((Id) => {
            store.dispatch(putCartAction(user,[Id]))
        })
        store.dispatch(addFavoriteAction(user, selected)).then(() => {
            setSelected([])
        })
    }

    useEffect(() => {
        if (user?.Login) {
            store.dispatch(getFavoritesAction(user.Meta?.Favorites))
        } else {
            store.dispatch(getFavoritesAction(localFavorites))
        }
    }, [user, localFavorites])
    return (
        <StyledFavoritesPage>
            <Breadcrumbs/>
            <Top>
                <Count>
                    <h1>Избранное</h1>
                    <span>{favorites?.length || 0} {declension((favorites?.length || 0), ['товар', 'товара', 'товаров'])}</span>
                </Count>
                <StyledCheckbox checked={selected.length === favorites.length} onClick={() => {
                    if (selected.length === favorites.length)
                        setSelected([])
                    else
                        setSelected(favorites.map(el => el.Id))

                }}>Выбрать все</StyledCheckbox>
            </Top>
            <Products>
                {favorites?.map(el => <ProductCard selected={selected.includes(el.Id)}
                                                   onSelect={(id) => {
                                                       if (selected.includes(el.Id)) {
                                                           setSelected(selected => selected.filter(el => el !== id))
                                                       } else {
                                                           setSelected(selected => [...selected, id])
                                                       }
                                                   }}
                                                   selectable
                                                   key={el.Id} {...el}/>)}
            </Products>
            <Actions>
                <Button onClick={handleCart}>В корзину</Button>
                <Button onClick={handleDelete} className={"cancel"}>Удалить</Button>
            </Actions>
        </StyledFavoritesPage>
    );
}

export default FavoritesPage;