import React from 'react';
import styled from "styled-components";
import {GroupType} from "../utils/types";
import {Link} from "react-router-dom";
import no_img from "../images/no_img.png";

const StyledNewSecondaryBanner = styled(Link)`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: 15px 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: center;
    max-width: 228px;
    text-decoration: none;
    width: 100%;
    align-items: center;


    &:hover {
        background: #5b77cb;
        color: #ffffff;
    }

    img {
        width: 100%;
        height: 73px;
        object-fit: contain;
        margin-bottom: 8px;
    }
`;

type SecondaryBannerType = {
    data: GroupType
}

function NewSecondaryBanner({data}: SecondaryBannerType) {
    return (
        <StyledNewSecondaryBanner to={`/catalog?group=${data.Id}`}>
            <img src={data?.ImageUrl || no_img} alt=""/>
            {data?.Name}
        </StyledNewSecondaryBanner>
    );
}

export default NewSecondaryBanner;