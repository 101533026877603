import React from 'react';
import styled from "styled-components";
import LogoSVG from "../icons/LogoSVG";
import {Link} from "react-router-dom";
import {Input} from "antd";
import ZoomSVG from "../icons/ZoomSVG";
import store from "../redux/store";
import {changeFeedbackOpenAction} from "../redux/actions/pageActions";
import Logo2SVG from "../icons/Logo2SVG";
import {mobileSizeRaw} from "../config";

const MobileMenu = styled.div`
  display: none;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;

  @media screen and (max-width: 999px) {
    display: flex;
  }
  
  a {
    color: #222;
    text-decoration: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
`;

const Menu = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 1fr;
  grid-column-gap: 100px;
  grid-row-gap: 30px;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;

  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
    text-decoration: none;
    color: #494949;
  }

  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;

    margin-bottom: 10px;
    color: #000000;
  }
`;

const MenuSubItem = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  text-decoration: none;
  color: #494949;
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 120px;

    .logo {
        width: 240px;
        height: 76px;
    }

    .logo2 {
        display: none;
    }

    @media screen and (max-width: 999px) {
        .logo {
            display: none;
        }

        .logo2 {
            display: flex;
        }
    }

    span {
        margin-top: 40px;
    }
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 308px;
  margin-left: 80px;

  svg {
    top: 2px;
    position: relative;
  }

  a {
    margin-top: 30px;
    color: #5b77cb !important;
  }

  .ant-input-affix-wrapper {
    background: none;
    border: 1px solid #494949;
    border-radius: 4px;
    padding: 0 20px 0 0;

    input {
      background: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      color: #494949;
      padding: 15px 10px 12px 23px;

      &::placeholder {
        color: #494949;
      }
    }
  }
`;

const Top = styled.div`
  display: flex;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  gap: 10px;

  a {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;

    color: #898989;
    text-decoration: none;
  }
`;

const StyledCommonFooter = styled.div`
  background: #F5F5F5;
  padding: 45px 18.75% 35px 18.75%;

  svg {
    flex: 0 0 auto;
  }

  @media screen and (max-width: 1919px) {
    padding: 45px 20px 35px 20px;
    ${TopRight} {
      margin-left: 60px;
      max-width: 265px;
    }

    ${TopLeft} {
      margin-right: 60px;
    }

    ${Top} {
      justify-content: space-between;
    }

    ${Menu} {
      grid-column-gap: 60px;
    }
  }

  @media screen and (max-width: 999px) {
    ${Bottom} {
      display: none;
    }

    ${Top} {
      flex-direction: column;
    }

    ${TopRight} {
      margin-left: 0;
      width: 100%;
      max-width: unset;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      .ant-input-affix-wrapper {
        max-width: 210px;
        border-color: #D7D8D3;

        input {
          &::placeholder {
            color: #8a8a8a;
          }
        }
      }

      a {
        margin: 0;
      }
    }

    ${TopLeft} {
      span {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    ${Menu} {
      display: none;
    }
  }
  @media screen and (${mobileSizeRaw}) {
    ${TopRight} {
      align-items: flex-start;

      .ant-input-affix-wrapper {
        max-width: 170px;

        input {
          &::placeholder {
            opacity: 0;
          }
        }
      }
    }
  }
`;

function CommonFooter() {
    return (
        <StyledCommonFooter>
            <Top>
                <TopLeft>
                    <Link to={"/"}>
                        <LogoSVG className={"logo"}/>
                        <Logo2SVG className={"logo2"}/>
                    </Link>
                    <span>Предоставляем качественный<br/> клиентский сервис покупателям.</span>
                </TopLeft>
                <Menu>
                    <MenuItem>
                        <h1>Меню</h1>
                        <MenuSubItem to={"/catalog"}>
                            Каталог
                        </MenuSubItem>
                        <MenuSubItem to={"/about"}>
                            О нас
                        </MenuSubItem>
                        <MenuSubItem to={"/media"}>
                            Медиатека
                        </MenuSubItem>
                    </MenuItem>
                    <MenuItem style={{minWidth: 183}}>
                        <h1>Контакты</h1>
                        <MenuSubItem to={"/location"}>
                            454081, г. Челябинск, Товарный двор, 14
                        </MenuSubItem>
                        <a href={"tel:+79227333371"}>
                            8 922 73 33 371
                        </a>
                        <MenuSubItem to={"/"}>
                            zarya.zav@yandex.ru
                        </MenuSubItem>
                    </MenuItem>
                </Menu>
                <MobileMenu>
                    <Link to={"/"}>Меню</Link>
                    <Link to={"/about"}>О нас</Link>
                    <Link to={"/repair"}>Сервис</Link>
                    <Link to={"/doc/personal"}>Согласие на обработку персональных данных</Link>
                    <Link to={"/doc/policy"}>Политика конфиденциальности</Link>
                    <Link to={"/doc/public"}>Договор публичной оферты</Link>

                </MobileMenu>
                <TopRight>
                    <Input placeholder={"Поиск по сайту"} suffix={<ZoomSVG/>}/>
                    <Link to={"#"} onClick={() => {
                        store.dispatch(changeFeedbackOpenAction(true))
                    }}>Напишите нам</Link>
                </TopRight>
            </Top>

            <Bottom>
                <Link to={"/doc/personal"}>Согласие на обработку персональных данных</Link>
                <Link to={"/doc/policy"}>Политика конфиденциальности</Link>
                <Link to={"/doc/public"}>Договор публичной оферты</Link>
            </Bottom>
        </StyledCommonFooter>
    );
}

export default CommonFooter;