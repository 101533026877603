import React from 'react';
import styled from "styled-components";
import {Button, Form, Input, Space, Upload} from "antd";
import LongArrowRightSvg from "../../icons/LongArrowRightSVG";
import {Link} from "react-router-dom";
import noavatar from "../../images/noavatar.svg";
import {UserType} from "../../utils/types";
import {useSelector} from "react-redux";
import {api, mobileSizeRaw} from "../../config";
import store from "../../redux/store";
import {editUserAction, uploadPhotoAction} from "../../redux/actions/pageActions";
import RequiredMarkSVG from "../../icons/RequiredMarkSVG";
import History from "../../utils/history";

const Avatar = styled.img`
  width: 128px;
  height: 128px;
  object-fit: cover;
  background: #f1f1f1;
  border-radius: 100%;
  margin-right: 32px;
`;

const StyledProfilePage = styled.div`
  display: flex;
  padding: 65px 18.75% 135px 18.75%;

  .buttonsWrap {
    align-items: center;
  }

  @media screen and (max-width: 1919px) {
    padding: 65px 20px 135px 20px;
  }


  @media screen and (max-width: 999px) {
    .buttonsWrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media screen and (${mobileSizeRaw}) {
    .buttonsWrap {
      width: 100%;

      .ant-space-item {
        width: 100%;
      }

      .ant-space {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        .ant-btn {
          width: 100%;
        }
      }
    }
  }

  .ant-upload {
    cursor: pointer;
  }

  .requiredMark {
    margin-left: 8px;
  }

  h1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 18px;
    color: #000000;
  }

  h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 42px;

    color: #8A8A8A;

    .requiredMark {
      margin-left: 0;
      margin-right: 2px;
    }
  }

  .ant-upload {
    user-select: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-decoration-line: underline;

    color: #5B77CB;
  }

  .ant-form {
    .ant-input {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;


      height: auto;
      padding: 16px 10px;

      color: #000000;
    }

    label {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;

      color: #8A8A8A;
    }
  }

  .actions {
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #5B77CB;
      text-decoration: underline;

      svg {
        margin-left: 12px;
      }
    }
  }

  .ant-btn {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    border: none;
    padding: 17px 28px;
    height: auto;
    background: #5B77CB;
    border-radius: 4px;

    span {
      color: #FFFFFF;
    }

    &.cancel {
      background: white;
      border: 1px solid #5B77CB;

      span {
        color: #5B77CB;
      }
    }
  }
`;

function ProfilePage({fill = false}) {
    const user: UserType = useSelector((state: any) => state.loginReducer.userData);

    return (
        <StyledProfilePage>
            {fill ? <Form requiredMark={false} initialValues={{...user, Password: ""}}
                          onFinish={(fields: any) => {
                              store.dispatch(editUserAction({...user, ...fields})).then(() => {
                                  History.push("/profile")
                              })
                          }}
                          layout={"vertical"}>
                    <h1>Для завершения регистрации, заполните данные ниже</h1>
                    <h2><RequiredMarkSVG/>Все поля обязательны к заполнению</h2>
                    <Form.Item>
                        <Upload showUploadList={false} customRequest={(_: any) => {
                            const reader = new FileReader();
                            reader.onloadend = function () {
                                store.dispatch(uploadPhotoAction(_.file))
                            }
                            if (_.file) {
                                reader.readAsDataURL(_.file);
                            }
                        }}>
                            <Avatar
                                src={user.PhotoUrl?.replace("//", "/") !== "" ? `/${user.PhotoUrl?.replace("//", "/")}` : noavatar}/>
                            {user.PhotoUrl?.replace("//", "/") !== "" ? "Изменить фото" : "+Добавить фото"}
                        </Upload>
                    </Form.Item>
                    <Form.Item name={"Name"} label={<>Имя <RequiredMarkSVG/></>} rules={[{
                        required: true,
                        message: "Поле является обязательным для заполнения!"
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"Email"} label={<>Эл. почта <RequiredMarkSVG/></>} rules={[{
                        required: true,
                        message: "Поле является обязательным для заполнения!"
                    }, {
                        type: "email",
                        message: "Формат почты введен не верно!"
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"Login"} label={"Телефон/Логин"}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item name={"Password"} label={<>Пароль <RequiredMarkSVG/></>} rules={[{
                        required: true,
                        message: "Поле является обязательным для заполнения!"
                    }]}>
                        <Input.Password placeholder={"Введите пароль"}/>
                    </Form.Item>
                    <Form.Item className={"actions"}>
                        <Space size={24}>
                            <Space size={24}>
                                <Button htmlType={"submit"}>Сохранить</Button>
                                <a href={`${api}/exit`}><Button className={"cancel"}>Выйти</Button></a>
                            </Space>
                            <Link to={"/orders"}>Мои заказы <LongArrowRightSvg/></Link>
                        </Space>
                    </Form.Item>
                </Form>
                : <Form initialValues={user}
                    // onFinish={(fields: any) => store.dispatch(editUserAction({...user, ...fields, Phone: fields.Login}))}
                        onFinish={(fields: any) => store.dispatch(editUserAction({...user, ...fields}))}
                        layout={"vertical"}>
                    <Form.Item>
                        <Upload showUploadList={false} customRequest={(_: any) => {
                            const reader = new FileReader();
                            reader.onloadend = function () {
                                store.dispatch(uploadPhotoAction(_.file))
                            }
                            if (_.file) {
                                reader.readAsDataURL(_.file);
                            }
                        }}>
                            <Avatar src={user.PhotoUrl !== "" ? `/${user.PhotoUrl?.replace("//", "/")}` : noavatar}/>
                            {user.PhotoUrl !== "" ? "Изменить фото" : "+Добавить фото"}
                        </Upload>
                    </Form.Item>
                    <Form.Item name={"Name"} label={"Имя"} rules={[{
                        required: true,
                        message: "Поле является обязательным для заполнения!"
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"Email"} label={"Эл. почта"} rules={[{
                        required: true,
                        message: "Поле является обязательным для заполнения!"
                    }, {
                        type: "email",
                        message: "Формат почты введен не верно!"
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"Login"} label={"Телефон/Логин"} rules={[{
                        required: true,
                        message: "Поле является обязательным для заполнения!"
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"Password"} label={"Пароль"} rules={[{
                        required: true,
                        message: "Поле является обязательным для заполнения!"
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item className={"actions"}>
                        <Space size={24} className={"buttonsWrap"}>
                            <Space size={24}>
                                <Button htmlType={"submit"}>Сохранить</Button>
                                <a href={`${api}/exit`}><Button className={"cancel"}>Выйти</Button></a>
                            </Space>
                            <Link to={"/orders"}>Мои заказы <LongArrowRightSvg/></Link>
                        </Space>
                    </Form.Item>
                </Form>}
        </StyledProfilePage>
    );
}

export default ProfilePage;