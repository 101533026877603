import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Breadcrumbs from "../../components/Breadcrumbs";
import {Button, Space} from "antd";
import ButtonStarSVG from "../../icons/ButtonStarSVG";
import CarouselLine from "../../components/CarouselLine";
import {ProductType, UserType} from "../../utils/types";
import {useSelector} from "react-redux";
import store from "../../redux/store";
import {addFavoriteAction, getItemAction, makeSoloOrderAction, putCartAction} from "../../redux/actions/pageActions";
import {useParams} from "react-router-dom";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
// @ts-ignore
import InnerImageZoom from 'react-inner-image-zoom'
import {IsInFavorites} from "../../utils/isInFavorites";
import SimpleModal from "../../components/SimpleModal";
import {useMediaQuery} from "usehooks-ts";
import {mobileSizeRaw} from "../../config";
import ImageViewer from "../../components/ImageViewer";
import ForOrderSVG from "../../images/ForOrderSVG";

const Photo = styled.div`
    display: flex;
    gap: 20px;

    img {
        max-width: 470px;
        object-fit: contain;
    }
`;

const AdditionalPhotos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    object-fit: contain;
`;

const SmallPhoto = styled.img`
    width: 94px;
    height: 94px;
    object-fit: contain;
    border: 1px solid #D7D8D3;
    cursor: pointer;

    &.selected {
        border: 1px solid #5a7ac7;
    }
`;

const SmallPhotoWrap = styled.div`
    display: flex;
    position: relative;

    &.opacity {
        ${SmallPhoto} {
            opacity: .7;
        }
    }

    ${SmallPhoto} {
        &:hover {
            opacity: 1;
        }
    }

    .nextArrow, .prevArrow {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F5F5;
        transform: translate(-50%, 50%);
        width: 52px;
        height: 52px;
        left: 50%;
        bottom: 0;
        cursor: pointer;

        &.prevArrow {
            top: -50%;
        }

        svg {
            scale: 1.5;

            path {
                stroke: #000000;
            }
        }
    }
`;


const Article = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;

    color: #8A8A8A;
    margin-bottom: 4px;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 26px;
    line-height: 140%;

    color: #000000;
`;

const Price = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;

    color: #000000;
    margin-bottom: 30px;

    span {
        &.price {
            font-weight: 700;
            font-size: 24px;
            line-height: 140%;
            margin-right: 10px;
            margin-left: 12px;
            color: #5B77CB;
        }

        &.oldPrice {
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;

            text-decoration-line: line-through;

            color: #C1C1C1;
        }
    }
`;

const Actions = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 30px;

    .ant-btn {
        background: #5B77CB;
        border-radius: 4px;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        display: flex;
        align-items: center;


        height: auto;
        padding: 15px 40px;
        border: 1px solid #5B77CB !important;

        span {
            color: #FFFFFF;
        }

        .active {
            path {
                fill: #5B77CB;
            }
        }

        &.second {
            border: 1px solid #5B77CB;
            background: white;

            span {
                color: #5B77CB;
            }
        }
    }
`;

const DescriptionText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    padding-top: 26px;
    border-top: 1px solid #D7D8D3;
    color: #000000;
`;

const Count = styled.div`
    margin-top: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;

    color: #C1C1C1;
`;

const FullDescription = styled.div`
    display: flex;
    max-width: 990px;
    flex-direction: column;
    margin-top: 75px;
    margin-bottom: 90px;

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #000000;
    }
`;


const Product = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 25px;
`;

const Top = styled.div`
    display: flex;
    gap: 80px;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
`;

const CharList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

`;

const CharItem = styled.div`
    display: flex;
    gap: 75px;

    span {
        &:first-child {
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            width: 300px;

            color: #000000;
        }

        &:last-child {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;

            color: #000000;
        }
    }
`;

const Characteristic = styled.div`

`;

const Suggestion = styled.div`
    margin-bottom: 185px;

    h1 {
        padding: 0 18.75% 0 18.75%;
    }

    @media screen and (max-width: 1919px) {
        h1 {
            padding: 0 20px 0 20px;
        }
    }
`;

const StyledProductPageWrap = styled.div`
    display: flex;
    flex-direction: column;

    h1 {
        font-weight: 600;
        font-size: 26px;
        line-height: 140%;
        margin-bottom: 25px;


        color: #000000;
    }
`;

const StyledProductPage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 36px 18.75% 150px 18.75%;

    .mobileActions {
        display: none;
    }

    @media screen and (max-width: 1919px) {
        padding: 36px 20px 150px 20px;
        .mobileActions {
            display: flex;
        }

        ${Actions} {
            &:not(.mobileActions) {
                flex-direction: column;
                align-items: flex-start;

                .ant-btn {
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 999px) {
        .mobileActions {
            display: none;
        }

        ${Actions} {
            &:not(.mobileActions) {
                flex-direction: row;
                align-items: center;

                .ant-btn {
                    padding: 15px 32px;

                    &:last-child {
                        display: flex;
                    }
                }
            }
        }

        ${Top} {
            flex-direction: column;

            ${Photo} {
                flex-direction: column;

                ${AdditionalPhotos} {
                    flex-direction: row;

                    .prevArrow {
                        transform: translateY(-50%) rotate(-90deg);
                        left: 0;
                        top: 50%;
                    }

                    .nextArrow {
                        transform: translateY(50%) rotate(-90deg);
                        left: calc(100% - 52px);
                        bottom: 50%;
                    }
                }
            }
        }
    }
    @media screen and (${mobileSizeRaw}) {
        .mobileActions {
            display: none;
        }

        ${Actions} {
            &:not(.mobileActions) {
                flex-direction: column;
                align-items: flex-start;

                .ant-btn {
                    justify-content: center;

                    &:not(:last-child) {
                        width: 100%;
                    }

                    &:last-child {
                        display: flex;
                    }
                }
            }
        }

        ${Top} {
            flex-direction: column;

            ${Photo} {
                flex-direction: column;

                ${AdditionalPhotos} {
                    flex-direction: row;

                    .prevArrow {
                        transform: translateY(-50%) rotate(-90deg);
                        left: 0;
                        top: 50%;
                    }

                    .nextArrow {
                        transform: translateY(50%) rotate(-90deg);
                        left: calc(100% - 52px);
                        bottom: 50%;
                    }
                }
            }
        }
    }
`;

function ProductPage() {
    const item: ProductType = useSelector((state: any) => state.pageReducer.item);
    const localFavorites: number[] = useSelector((state: any) => state.pageReducer.localFavorites);

    const mobileSize = useMediaQuery(`(${mobileSizeRaw})`)

    const [nextPhoto, setNextPhoto] = useState<boolean>(false);

    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    const {id} = useParams();
    const user: UserType = useSelector((state: any) => state.loginReducer.userData);
    const [selectedPhoto, setSelectedPhoto] = useState<any>("");
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    useEffect(() => {
        store.dispatch(getItemAction(id))
        setSelectedPhoto("")
    }, [id])

    return (
        <StyledProductPageWrap>
            <SimpleModal open={modalOpen} onCancel={() => setModalOpen(false)}>
                Ваш заказ успешно оформлен, статус заказа можете отслеживать в личном кабинете
            </SimpleModal>
            <StyledProductPage>
                <Breadcrumbs/>
                <Product>
                    <Top>
                        <ImageViewer item={item}/>
                        <Description>
                            <Actions className={"mobileActions"}>
                                <Button onClick={() => {
                                    store.dispatch(addFavoriteAction(user, [item.Id]))
                                }} className={"second"}><ButtonStarSVG
                                    className={`${IsInFavorites(user, localFavorites, item.Id) ? "active" : ""}`}/></Button>
                            </Actions>
                            <Article>{item.Code}</Article>
                            <Title>{item.Name}</Title>
                            <Price>
                                {item.NewPrice ? <>
                                    Цена: <span className="price">{formatter.format(item.NewPrice)}</span>
                                    {item?.OldPrice > 0 &&
                                        <span className="oldPrice">{formatter.format(item.OldPrice)}</span>}

                                </> : <Space style={{fontSize: 20, fontWeight: 700, alignItems: "center", display: "flex"}} size={0}><ForOrderSVG/>Под
                                    заказ</Space>}
                            </Price>
                            <Actions>
                                {user?.Login && <Button onClick={() => {
                                    store.dispatch(makeSoloOrderAction({
                                        Positions: [
                                            {
                                                Id: item.Id,
                                                Count: 1
                                            }
                                        ]
                                    })).then(() => {
                                        setModalOpen(true)
                                    })
                                }}>Купить в 1 клик</Button>}
                                <Button onClick={() => {
                                    store.dispatch(putCartAction(user, [item.Id]))
                                }} className={"second"}>В корзину</Button>
                                <Button onClick={() => {
                                    store.dispatch(addFavoriteAction(user, [item.Id]))
                                }} className={"second"}><ButtonStarSVG
                                    className={`${IsInFavorites(user, localFavorites, item.Id) ? "active" : ""}`}/></Button></Actions>
                            <DescriptionText>
                                {item.ShortDesc}
                            </DescriptionText>
                            <Count>
                                {(item.Count > 0 && item.NewPrice !== 0) ? "В наличии" : "Нет в наличии"}
                            </Count>
                        </Description>
                    </Top>
                    <FullDescription>
                        <h1>Описание товара:</h1>
                        <p>
                            {item.FullDesc}
                        </p>
                    </FullDescription>
                    <Characteristic>
                        <h1>Характеристика товара:</h1>
                        <CharList>
                            {item.Meta?.Characteristics?.map((el, index) => <CharItem key={index}>
                                <span>{el.Name}:</span>
                                <span>{el.Value}</span>
                            </CharItem>)}
                        </CharList>
                    </Characteristic>
                </Product>
            </StyledProductPage>
            <Suggestion>
                <h1>С этим товаром заказывают:</h1>
                <CarouselLine type={"popular"}/>
            </Suggestion>
        </StyledProductPageWrap>
    );
}

export default ProductPage;