import styled from "styled-components";
import StarSVG from "../icons/StarSVG";
import {ProductType, UserType} from "../utils/types";
import History from "../utils/history";
import store from "../redux/store";
import {addFavoriteAction, putCartAction} from "../redux/actions/pageActions";
import {useSelector} from "react-redux";
import {IsInFavorites} from "../utils/isInFavorites";
import {StyledCheckbox} from "./StyledCheckbox";
import no_img from "../images/no_img.png"
import {useMediaQuery} from "usehooks-ts";
import {StyledButton} from "./StyledButton";
import ForOrderSVG from "../images/ForOrderSVG";
import {Space} from "antd";

const Availability = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 7px;

    color: #5B77CB;

    &.out {
        color: #A0A0A0;
    }
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 20px;

    color: #000000;
`;

const Price = styled.div`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;

    color: #000000;
`;

const OldPriceTitle = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-decoration-line: line-through;
    margin-left: 10px;

    color: #C1C1C1;
`;

export const StyledProductCard = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 284px;
    width: 100%;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    padding: 32px 25px 25px 25px;
    position: relative;
    cursor: pointer;


    @media screen and (max-width: 360px) {
        border: 0;
        &::after {
            content: "";
            border-bottom: 1px solid #e7e7e7;
            position: absolute;
            bottom: 0;
            width: calc(100% + 40px);
            left: -20px;
        }
    }

    .ant-checkbox {
        position: absolute;
        top: 16px;
        left: 16px;
    }

    .star {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;

        &.active {
            path {
                stroke: none;
                fill: #5B77CB;
            }
        }

        path {
            stroke: #A0A0A0;
            fill: none;
        }

        &:hover {
            path {
                stroke: none;
                fill: #5B77CB;
            }
        }
    }

    img {
        width: 182px;
        height: 166px;
        object-fit: contain;
        margin-bottom: 23px;
        place-self: center;
    }
`;
export const MobileStyledProductCard = styled(StyledProductCard)`
    flex-direction: row;
    gap: 14px;
    padding-left: 0;
    padding-right: 0;

    ${StyledButton} {
        background: #5B77CB;
        border-radius: 4px;
        padding: 8px 25px;
    }

    img {
        width: 107px;
        height: 97px;
        object-fit: contain;
        margin-bottom: 0;
        place-self: center;
    }

    ${Availability} {
        font-size: 14px;
        margin: 0;
    }

    ${Title} {
        margin-bottom: 10px;
    }

    ${OldPriceTitle} {
        display: none;
    }

    .left {

    }

    .right {
        .top {

        }

        .middle {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        .bottom {
            margin-top: 14px;
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }

    .star {
        position: relative;
        top: 0;
        right: 0;
    }
`

function ProductCard({
                         Id,
                         Name,
                         NewPrice,
                         OldPrice,
                         Count,
                         ImageUrls,
                         ImageMain,
                         selectable,
                         selected,
                         onSelect
                     }: ProductType & { selectable?: boolean, onSelect?: (id: number) => void, selected?: boolean }) {
    const user: UserType = useSelector((state: any) => state.loginReducer.userData);
    const localFavorites: number[] = useSelector((state: any) => state.pageReducer.localFavorites);
    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    const mobileSize = useMediaQuery('(max-width: 360px)')
    const handleCart = (Id: number) => {
        store.dispatch(putCartAction(user, [Id]))
    }


    return (
        mobileSize ? <MobileStyledProductCard>
            {selectable && <StyledCheckbox checked={selected} onClick={(e) => {
                e.stopPropagation();
                onSelect && onSelect(Id)
            }}/>}
            <span style={{display: "flex"}} onClick={() => History.push(`/catalog/${Id}`)}>
                <span className={"left"}>
                <img src={ImageUrls?.[ImageMain] ? `/${ImageUrls?.[ImageMain]?.replace("//", "/")}` : no_img} alt=""/>
            </span>
            <span className={"right"}>
                <span className="top">
                <Title className={"title"}>
                    {Name}
                </Title>
                    </span>
                <span className="middle">
                    <Availability className={(Count > 0 && NewPrice !== 0) ? "" : "out"}>
                        {(Count > 0 && NewPrice !== 0) ? "В наличии" : "Нет в наличии"}
                    </Availability>
                    <Price>
                       {NewPrice ? <>
                               {formatter.format(NewPrice)}
                               {OldPrice > 0 && <OldPriceTitle>{formatter.format(OldPrice)}</OldPriceTitle>}
                           </> :
                           <Space style={{fontSize: 12, alignItems: "center", display: "flex", flexDirection: "column"}}
                                  size={0}><ForOrderSVG style={{width: 40}}/>Под
                               заказ</Space>}
                    </Price>
                </span>
                <span className="bottom">
                    <StarSVG onClick={(e: any) => {
                        e.stopPropagation();
                        if (selected)
                            onSelect && onSelect(Id)
                        store.dispatch(addFavoriteAction(user, [Id]))
                    }} className={`star ${IsInFavorites(user, localFavorites, Id) ? "active" : ""}`}/>
                    <StyledButton onClick={(e) => {
                        e.stopPropagation();
                        handleCart(Id)
                    }}>
                        В корзину
                    </StyledButton>
                </span>
            </span>
            </span>
        </MobileStyledProductCard> : <StyledProductCard onClick={() => History.push(`/catalog/${Id}`)}>
            {selectable && <StyledCheckbox checked={selected} onClick={(e) => {
                e.stopPropagation();
                onSelect && onSelect(Id)
            }}/>}
            <StarSVG onClick={(e: any) => {
                e.stopPropagation();
                if (selected)
                    onSelect && onSelect(Id)
                store.dispatch(addFavoriteAction(user, [Id]))
            }} className={`star ${IsInFavorites(user, localFavorites, Id) ? "active" : ""}`}/>
            <img src={ImageUrls?.[ImageMain] ? `/${ImageUrls?.[ImageMain]?.replace("//", "/")}` : no_img} alt=""/>
            <Availability className={(Count > 0 && NewPrice !== 0) ? "" : "out"}>
                {(Count > 0 && NewPrice !== 0) ? "В наличии" : "Нет в наличии"}
            </Availability>
            <Title className={"title"}>
                {Name}
            </Title>
            <Price>
                {NewPrice ? <>
                    {formatter.format(NewPrice)}
                    {OldPrice > 0 && <OldPriceTitle>{formatter.format(OldPrice)}</OldPriceTitle>}
                </> : <Space style={{fontSize: 20, alignItems: "center", display: "flex"}} size={0}><ForOrderSVG/>Под
                    заказ</Space>}

            </Price>
        </StyledProductCard>
    );
}

export default ProductCard;