import React, {useEffect} from 'react';
import styled from "styled-components";
import aboutImage from "../../images/aboutImage.jpg"
import grayLogo from "../../images/grayLogo.svg"
import Breadcrumbs, {StyledBreadcrumbs} from "../../components/Breadcrumbs";
import certificate from "../../images/certificate.png";
import OpenRectangle from "../../icons/OpenRectangle";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {mobileSizeRaw} from "../../config";


const AboutText = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    margin-bottom: 120px;
`;


const Partners = styled.div`
    height: 100%;
    margin-bottom: 130px;
    padding-top: 62px;

    #widget1 {
        img {
            vertical-align: initial;
        }
    }

    .swiper-wrapper {
        align-items: center;
    }

    img {
        user-select: none;
        pointer-events: none;
    }
`;

const CertificateWrap = styled.div`
    display: flex;
    justify-content: space-around;
`;

const Certificate = styled.div`
    display: flex;
    align-items: center;
    height: 195px;

    span {
        margin-left: 25px;
        max-width: 250px;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;

        color: #5B77CB;
    }

    @media screen and (max-width: 999px) {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
        max-width: 210px;

        span {
            margin: 0;
            text-align: start;
        }

        img {
            height: 200px;
        }
    }
    @media screen and (${mobileSizeRaw}) {
        max-width: unset;

        span {
            margin: 0;
            text-align: start;
        }

        img {
            height: 450px;
        }
    }
`;
const ImageWrap = styled.div`
    display: flex;
    position: relative;
    height: 100%;

    img {
        object-fit: contain;
        padding-bottom: 10px;
        padding-right: 10px;
    }

    a {
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0;
    }
`;

const Certificates = styled.div`
    height: 100%;
    margin-bottom: 130px;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 1920px) {
        padding: 0 18.65% 0 18.65%;
    }
    @media screen and (max-width: 1919px) {
        padding: 0
    }

    .mySwiper {
        @media screen and (max-width: 1919px) {
            .swiper-button-next {
                left: calc(50% + 36px);
                margin-top: 0;
                top: calc(220px + 50px);
            }

            .swiper-button-prev {
                left: calc(50% - 36px);
                margin-top: 0;
                top: calc(220px + 50px);
            }
        }
        @media screen and (max-width: 999px) {
            .swiper-button-next {
                top: calc(340px + 50px);
            }

            .swiper-button-prev {
                top: calc(340px + 50px);
            }
        }
        @media screen and (${mobileSizeRaw}) {
            .swiper-button-next {
                top: calc(540px + 50px);
            }

            .swiper-button-prev {
                top: calc(540px + 50px);
            }
        }
    }

`;

const StyledAboutPage = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    padding-top: 35px;

    .mySwiper {
        width: 100%;

        .swiper-button-next, .swiper-button-prev {
            width: 52px;
            height: 52px;
            background: #F5F5F5;

            &::after {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 600;
                color: #000;
            }
        }

        &:not(.certificateSwiper) {
            @media screen and (min-width: 1920px) {
                .swiper-button-next {
                    left: 78%;
                }

                .swiper-button-prev {
                    left: 18%;
                }
            }
            @media screen and (max-width: 1919px) {
                .swiper-button-next {
                    left: 65%;
                }

                .swiper-button-prev {
                    left: 30%;
                }
            }
            @media screen and (max-width: 1919px) {
                padding-bottom: 102px;
                .swiper-button-next {
                    left: calc(50% + 36px);
                    margin-top: 0;
                    top: calc(70px + 50px);
                }

                .swiper-button-prev {
                    left: calc(50% - 36px);
                    margin-top: 0;
                    top: calc(70px + 50px);
                }
            }
        }

        .swiper-slide {
            width: 30%;
            text-align: center;
        }
    }

    .certificateSwiper {

        @media screen and (max-width: 1920px) {
            padding: 54px 100px 0 100px;
        }
        @media screen and (max-width: 1919px) {
            padding: 54px 20px 100px 20px;
        }

        .swiper-slide {
            width: 100%;
            text-align: center;


            display: flex;
            justify-content: center;
            align-items: center;

            &:not(.swiper-slide-next):not(.swiper-slide-active) {
                opacity: 0;
                transition: opacity 1s;
            }
        }
    }

    ${StyledBreadcrumbs} {
        padding: 0 18.65%;
        @media screen and (max-width: 1919px) {
            padding: 0 20px;
        }
    }

    h1, h2, p, h3 {
        padding: 0 18.75%;
        margin: 0;
        @media screen and (max-width: 1919px) {
            padding: 0 20px;
        }
    }

    h1 {
        margin-top: 52px;
        margin-bottom: 45px;
        font-weight: 600;
        font-size: 28px;
        @media screen and (max-width: 999px) {
            font-size: 24px;
        }
    }

    h2 {
        font-weight: 600;
        font-size: 28px;
    }

    h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        margin-top: 20px;
        color: #000000;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #000000;
        margin-bottom: 15px;
    }

    > img {
        height: 403px;
        object-fit: cover;
        margin-bottom: 70px;
    }
`;

function AboutPage() {

    useEffect(() => {
        const d = document;
        const s = 'script';
        let js, fjs = d.getElementsByTagName(s)[0];
        js = d.createElement(s);
        js.src = "https://widgets.mos.ru/cnews/citywidgets.js";
        // @ts-ignore
        fjs.parentNode.insertBefore(js, fjs);

        const i = setInterval(function () {
            // @ts-ignore
            if (window.city_widget && document.readyState === "complete") {
                // @ts-ignore
                city_widget("300x200", "#widget1");
                clearInterval(i);
            }
        }, 50);
        const timeout = setTimeout(function () {
            clearInterval(i)
        }, 5000);
        return () => {
            clearInterval(i)
            clearTimeout(timeout)
        }
    }, []);

    const items = [
        <img src={grayLogo} data-value="1" alt={""}/>,
        <img src={grayLogo} data-value="2" alt={""}/>,
        <img src={grayLogo} data-value="3" alt={""}/>,
        <img src={grayLogo} data-value="4" alt={""}/>,
        <img src={grayLogo} data-value="5" alt={""}/>,
        <img src={grayLogo} data-value="6" alt={""}/>,
    ];
    let certificates = [
        <Certificate key={1}>
            <ImageWrap>
                <img src={certificate} alt="certificate"/>
                <a href={"/"}><OpenRectangle/></a>
            </ImageWrap>
            <span>Сертификат который подтверждает деятельность компании НИЗ ЗАРЯ</span>
        </Certificate>,
        <Certificate key={2}>
            <ImageWrap>
                <img src={certificate} alt="certificate"/>
                <a href={"/"}><OpenRectangle/></a>
            </ImageWrap>
            <span>Сертификат который подтверждает деятельность компании НИЗ ЗАРЯ</span>
        </Certificate>,
        <Certificate key={3}>
            <ImageWrap>
                <img src={certificate} alt="certificate"/>
                <a href={"/"}><OpenRectangle/></a>
            </ImageWrap>
            <span>Сертификат который подтверждает деятельность компании НИЗ ЗАРЯ</span>
        </Certificate>,
        <Certificate key={4}>
            <ImageWrap>
                <img src={certificate} alt="certificate"/>
                <a href={"/"}><OpenRectangle/></a>
            </ImageWrap>
            <span>Сертификат который подтверждает деятельность компании НИЗ ЗАРЯ</span>
        </Certificate>
    ];

    return (
        <StyledAboutPage>
            <Breadcrumbs/>
            <h1>НИЗ ЗАРЯ-поставляем измерительные приборы<br/> на промышленные предприятия России. </h1>
            <img src={aboutImage} alt=""/>
            <h2>О компании</h2>
            <AboutText>
                <p>Наша компания занимается поставкой промышленного измерительного инструмента, оборудования и приборов
                    высокого качества соответствующего ГОСТ.</p>
                <p>Мы являемся одним из крупнейших поставщиков качественного измерительного инструмента соответствующего
                    ГОСТ, ТУ, DIN.</p>
                <p>А так же имеем собственное производство высокоточного измерительного инструмента, производимый
                    инструмент соответствует ГОСТ и внесен в реестр СИ под собственным брендом.</p>
            </AboutText>
            <h2>Наши партнеры</h2>
            <Partners>
                <Swiper
                    breakpoints={{
                        1920: {
                            slidesPerView: 5
                        },
                        1000: {
                            slidesPerView: 3
                        },
                        480: {
                            slidesPerView: 1
                        }
                    }}
                    initialSlide={1}
                    spaceBetween={30}
                    className="mySwiper"
                    centeredSlides={true}
                    navigation={true}
                    modules={[Navigation]}
                >
                    <SwiperSlide>
                        <img src={grayLogo} data-value="2" alt={""}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div id="widget1">
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={grayLogo} data-value="3" alt={""}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={grayLogo} data-value="4" alt={""}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={grayLogo} data-value="5" alt={""}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={grayLogo} data-value="6" alt={""}/>
                    </SwiperSlide>
                </Swiper>
            </Partners>
            {/*<h2>Сертификаты:</h2>*/}
            {/*<h3>Деятельность нашей компании подтверждена всеми необходимыми документами</h3>*/}
            {/*<Certificates>*/}
            {/*    <Swiper*/}
            {/*        breakpoints={{*/}
            {/*            480: {*/}
            {/*                slidesPerView: 2*/}
            {/*            },*/}
            {/*            479: {*/}
            {/*                slidesPerView: 1*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        initialSlide={1}*/}
            {/*        spaceBetween={30}*/}
            {/*        className="mySwiper certificateSwiper"*/}
            {/*        navigation={true}*/}
            {/*        modules={[Navigation]}*/}
            {/*    >*/}
            {/*        <SwiperSlide>*/}
            {/*            <Certificate>*/}
            {/*                <ImageWrap>*/}
            {/*                    <img src={certificate} alt="certificate"/>*/}
            {/*                    <a href={"/"}><OpenRectangle/></a>*/}
            {/*                </ImageWrap>*/}
            {/*                <span>Сертификат который подтверждает деятельность компании НИЗ ЗАРЯ</span>*/}
            {/*            </Certificate>*/}
            {/*        </SwiperSlide>*/}
            {/*        <SwiperSlide>*/}
            {/*            <Certificate>*/}
            {/*                <ImageWrap>*/}
            {/*                    <img src={certificate} alt="certificate"/>*/}
            {/*                    <a href={"/"}><OpenRectangle/></a>*/}
            {/*                </ImageWrap>*/}
            {/*                <span>Сертификат который подтверждает деятельность компании НИЗ ЗАРЯ</span>*/}
            {/*            </Certificate>*/}
            {/*        </SwiperSlide>*/}
            {/*        <SwiperSlide>*/}
            {/*            <Certificate>*/}
            {/*                <ImageWrap>*/}
            {/*                    <img src={certificate} alt="certificate"/>*/}
            {/*                    <a href={"/"}><OpenRectangle/></a>*/}
            {/*                </ImageWrap>*/}
            {/*                <span>Сертификат который подтверждает деятельность компании НИЗ ЗАРЯ</span>*/}
            {/*            </Certificate>*/}
            {/*        </SwiperSlide>*/}

            {/*    </Swiper>*/}
            {/*</Certificates>*/}
        </StyledAboutPage>
    );
}

export default AboutPage;