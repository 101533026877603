import React from 'react';
import styled from "styled-components";
import ImageViewer, {StyledImageViewer} from "./ImageViewer";
import {GroupType} from "../utils/types";

const Top = styled.div`
    display: flex;
    gap: 35px;
`;
const Description = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    h1 {
        font-size: 28px;
        font-weight: 600;
    }

    p {
        font-size: 18px;
        font-weight: 400;
    }
`;
const Bottom = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledCatalogPreview = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;

    ${StyledImageViewer} {
        max-width: 386px;
    }
`;
type CatalogPreviewType = {
    group: GroupType
}

function CatalogPreview({group}: CatalogPreviewType) {
    return (
        <StyledCatalogPreview>
            <Top>
                <ImageViewer item={group}/>
                <Description>
                    <h1>{group?.Name}</h1>
                    <p>{group?.FullDesc}</p>
                </Description>
            </Top>
            <Bottom>
                <div className='text ck-content' dangerouslySetInnerHTML={{__html: group?.HTML}}></div>
            </Bottom>
        </StyledCatalogPreview>
    );
}

export default CatalogPreview;