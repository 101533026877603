import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import ProductCard, {StyledProductCard} from "../../components/ProductCard";
import {Collapse, Menu, Pagination} from "antd";
import {GroupType, MenuItemType, PaginationType, ProductType} from "../../utils/types";
import {useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {
    changeCatalogOpenAction,
    getGroupAction,
    getItemsAction,
    getItemsByGroupAction
} from "../../redux/actions/pageActions";
import store from "../../redux/store";
import {declension} from "../../utils/Declension";
import Breadcrumbs from "../../components/Breadcrumbs";
import ArrowDownSVG from "../../icons/ArrowDownSVG";
import History from "../../utils/history";
import CatalogComponent from "../../components/CatalogComponent";
import {useMediaQuery} from "usehooks-ts";
import {mobileSizeRaw} from "../../config";
import CatalogPreview from "../../components/CatalogPreview";

const {Panel} = Collapse;

const Products = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProductItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;
const SubItem = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 12px;
  margin-left: 14px;
  color: #404040;

  &:hover {
    color: #5a7ac7;
  }
`;

const CatalogItems = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;

  /* black */

  color: #311C1C;

  &:hover {
    color: #5a7ac7;
  }
`;


const Top = styled.div`
  display: flex;
  margin-bottom: 16px;

  h1 {
    overflow-wrap: anywhere;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-left: 15px;
    padding-top: 2px;

    color: #8A8A8A;
  }
`;

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 50px;


  .ant-pagination-next, .ant-pagination-prev, .ant-pagination-jump-next, .ant-pagination-jump-prev {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 0;

    .ant-pagination-item-ellipsis {
      text-indent: -0.27em;
    }

    .ant-pagination-item-link {
      border-radius: 0;
    }
  }

  .ant-pagination-next {
    margin-left: 24px;
    background: #F5F5F5;
  }

  .ant-pagination-prev {
    margin-right: 24px;
    background: #F5F5F5;
  }

  .ant-pagination-disabled {
    opacity: 0;

    button {
      cursor: default !important;
    }
  }

  .ant-pagination-item {
    border-radius: 0;
    background: #f5f5f5;
    border: none;
    margin: 0;
    font-size: 16px;
    padding: 10px;
    width: 52px;
    height: 52px;

    a {
      top: 2px;
      font-weight: 400;
      position: relative;
    }

    &.ant-pagination-item-active {
      background: #5B77CB;

      a {
        color: #FFFFFF;
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  margin-top: 25px;
`;

const Catalog = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 260px;
  margin-right: 40px;
  max-width: 265px;

  @media screen and (max-width: 999px) {
    display: none;
  }

  a {
    text-decoration: none;
  }

  h1 {
    margin-bottom: 20px;
  }

  .ant-collapse-item {
    svg {
      transform: rotate(90deg);
    }
  }

  .ant-collapse-item-active {
    svg {
      transform: rotate(-90deg) !important;
    }
  }

  .ant-collapse-header {
    flex-direction: row-reverse;
  }

  .ant-collapse-header, .ant-collapse-content-box {
    padding-left: 0 !important;
  }
`;

const StyledCatalogPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 36px 18.75% 135px 18.75%;

  @media screen and (max-width: 1919px) {
    padding: 36px 20px 135px 20px;
  }

  @media screen and (max-width: 999px) {
    ${StyledProductCard} {
      max-width: 210px;

      img {
        width: 112px;
      }

      .title {
        font-size: 16px;
      }
    }

    ${Content} {
      flex-direction: column;
      gap: 100px;
    }

    ${Catalog} {
      max-width: unset;

      .ant-menu {
        .ant-menu-submenu-title {
          max-width: unset;
        }

        .ant-menu-item {
          max-width: unset;
        }
      }
    }
  }

  @media screen and (${mobileSizeRaw}) {
    ${StyledProductCard} {
      max-width: 320px;

      img {
        width: 206px;
      }

      .title {
        font-size: 18px;
      }
    }
  }
  @media screen and (max-width: 360px) {
    ${StyledProductCard} {
      max-width: 320px;

      img {
        width: 107px;
      }

      .title {
        font-size: 16px;
      }
    }
  }

  .ant-menu {
    border: none !important;

    .ant-menu-submenu-title {
      max-width: 260px;

    }

    .ant-menu-item {
      max-width: 260px;
    }
  }

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;

    color: #311C1C;
  }
`;


function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function CatalogPage() {
    const groups: GroupType[] = useSelector((state: any) => state.pageReducer.groups);
    const group: GroupType = useSelector((state: any) => state.pageReducer.group);
    const items: PaginationType<ProductType[]> = useSelector((state: any) => state.pageReducer.items);
    const [page, setPage] = useState<number>(1);

    const showCatalog = useSelector((state: any) => state.pageReducer.showCatalog)

    const mobileSize = useMediaQuery('(max-width: 999px)')

    const handleGetCatalog = (Page: number) => {
        // console.log(Page);
        window.scrollTo(0, 0)
        setPage(Page);
    }

    let query = useQuery();

    let groupId = useMemo(() => Object.fromEntries(query)?.group, [query]);
    let filter = useMemo(() => Object.fromEntries(query)?.filter, [query]);

    const getItem = (
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItemType[],
        type?: 'group',
    ): MenuItemType => {
        return {
            popupOffset: [0, 0],
            // style: {width: 260},
            key,
            icon,
            children,
            label,
            type,
            onTitleClick: () => {
                // console.log(key);
                History.push(`/catalog?group=${key}`)
            },
            onClick: () => {
                // !children && console.log(key);
                !children && History.push(`/catalog?group=${key}`)
            }
        } as MenuItemType;
    }

    const menuItems: MenuItemType[] = groups.map((group) => getItem(group.Name, group.Id, null, group.ChildGroups?.map((sub_group) =>
        getItem(sub_group.Name, sub_group.Id, null, sub_group.ChildGroups?.map((sub_sub_group) =>
            getItem(sub_sub_group.Name, sub_sub_group.Id, null)
        ) || undefined)
    )));

    useEffect(() => {
        return () => {
            store.dispatch(changeCatalogOpenAction(false))
        }
    }, []);

    useEffect(() => {
        if (groupId !== undefined) {
            store.dispatch(getGroupAction(groupId))
            store.dispatch(getItemsByGroupAction(groupId, page, {
                filter: filter
            }))
        } else {
            store.dispatch(getGroupAction(null))
            store.dispatch(getItemsAction(page, {
                filter: filter
            }))
        }
    }, [page, groupId, filter])


    return (
        <StyledCatalogPage style={showCatalog ? {paddingRight: 0, paddingLeft: 0} : {}}>
            {(showCatalog && mobileSize) ?
                <CatalogComponent onClose={() => store.dispatch(changeCatalogOpenAction(false))}/> : <>
                    <Breadcrumbs
                        onCatalog={() => mobileSize ? store.dispatch(changeCatalogOpenAction(true)) : History.push('/catalog')}/>
                    <Content>
                        <Catalog>
                            <Link to={"/catalog"}><h1>Каталог</h1></Link>
                            <Menu
                                selectable={false}
                                expandIcon={<ArrowDownSVG style={{transform: "rotate(-90deg)"}}/>} mode="vertical"
                                items={menuItems}/>

                            {/*<Collapse collapsible="icon" ghost>*/}
                            {/*    {groups?.map((parentGroup) => <Panel key={parentGroup.Id} header={*/}
                            {/*        <Link to={`/catalog?group=${parentGroup.Id}`}><CatalogItems>*/}
                            {/*            {parentGroup.Name}*/}
                            {/*        </CatalogItems></Link>}>*/}
                            {/*        {parentGroup.ChildGroups?.map(childGroup => <Link key={childGroup.Id}*/}
                            {/*                                                          to={`/catalog?group=${childGroup.Id}`}>*/}
                            {/*            <SubItem>*/}
                            {/*                {childGroup.Name}*/}
                            {/*            </SubItem>*/}
                            {/*        </Link>)}*/}
                            {/*    </Panel>)}*/}
                            {/*</Collapse>*/}
                        </Catalog>
                        <Products>
                            {!group.HTML && <Top>
                                <h1>{group?.Name || "Все товары"}</h1>
                                <span>{(group?.ChildItemIds?.length || items.Total)} {declension((group?.ChildItemIds?.length || items.Total), ['товар', 'товара', 'товаров'])}</span>
                            </Top>}

                            {group.HTML ? <CatalogPreview group={group}/> : <ProductItems>
                                {items?.Data?.map(el => <ProductCard key={el.Id} {...el}/>)}
                            </ProductItems>}
                            {!group.HTML && <StyledPagination {...{
                                current: items?.Page || 0,
                                total: items?.Total || 0,
                                pageSize: items?.Count || 0,
                                onChange: handleGetCatalog,
                                showSizeChanger: false
                            }}/>}
                        </Products>
                    </Content>
                </>}
        </StyledCatalogPage>
    );
}

export default CatalogPage;