import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Collapse, Select, Space, Tag} from "antd";
import {OrderStatusType, OrderType} from "../../utils/types";
import {useSearchParams} from "react-router-dom";
import Arrow45SVG from "../../icons/Arrow45SVG";
import {useSelector} from "react-redux";
import store from "../../redux/store";
import {getOrdersAction} from "../../redux/actions/pageActions";
import * as _ from "lodash";
import moment from "moment";
import SimpleModal from "../../components/SimpleModal";
import {mobileSizeRaw} from "../../config";

const {Option} = Select;

const {Panel} = Collapse;

const Tags = styled.div`
    display: flex;
    margin-top: 14px;

    .ant-tag {
        padding: 2px 12px;
        border: 1px solid #DCDCDC;
        background: #ffffff;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        cursor: pointer;

        &.selected {
            border: 1px solid #5b77cb
        }
    }
`;

const TopRight = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-left: 32px;
`;

const Top = styled.div`
    display: flex;
    width: 100%;
`;

const Order = styled.div`
    display: flex;

    .rightWrap {
        .status {
            display: none;
        }
    }

    justify-content: space-between;
    position: relative;

    .arrow {
        bottom: -16px;
        right: -16px;
        position: absolute;

        &.active {
            transform: rotate(-90deg) scaleZ(-1);
        }
    }

    .left {
        display: flex;
        flex-direction: column;
        margin-right: 35px;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .status {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        /* or 22px */
        padding: 6px 18px;

        color: #FFFFFF;
        white-space: nowrap;

        background: #5B77CB;
        border-radius: 4px;
    }

    h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;

        display: flex;
        align-items: center;

        color: #311C1C;
    }

    .date {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #8A8A8A;
    }

    .price {
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;

        color: #5B77CB;
    }

    .count {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #8A8A8A;
    }


    @media screen and (${mobileSizeRaw}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        .ant-space {
            span {
                display: none;
            }
        }

        .rightWrap {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: flex-start;

            .status {
                display: block;
            }
        }
    }
`;

const OrderItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        max-width: 315px;
        color: #000000;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .price {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 140%;

        color: #000000;
    }

    .count {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #8A8A8A;
    }
`;

const CollapseBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
    border-top: 1px solid #dcdcdc;
    margin: 0 32px;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    padding-top: 30px;
    padding-bottom: 24px;

    color: #000000;

`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;

    .ant-collapse {
        background: #fff;
        border-radius: 4px;

        .ant-collapse-header {
            border-bottom: 0 !important;
            padding: 32px !important;
        }

        .ant-collapse-content-box {
            padding-left: 0;
            padding-right: 0;
        }

        .ant-collapse-content {
            border-top: 0 !important;
        }

        .ant-collapse-header, .ant-collapse-item {
            border-radius: 0 0 4px 4px !important;
        }
    }
`;

const StyledMyOrders = styled.div`
    display: flex;
    flex-direction: column;
    padding: 65px 18.75% 135px 18.75%;
    width: 100%;

    @media screen and (max-width: 1919px) {
        padding: 65px 20px 135px 20px;
    }

`;

const StyledSelect = styled(Select)`
    max-width: 240px;
    width: 100%;


    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    display: flex;
    align-items: center;

    color: #898989;

    .ant-select-selection-item {
        opacity: 0.7;
    }

    .ant-select-selector {
        border: 1px solid #DCDCDC !important;
        border-radius: 4px;
        height: 41px !important;
        align-items: center;
    }
`;

function CollapseItem({Id, Status, Positions, UpdatedDate}: OrderType) {
    const [active, setActive] = useState<0 | 1>(0);
    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    const getStatusName = (value: number) => {
        switch (value) {
            case OrderStatusType.inWork:
                return "В работе"
            case OrderStatusType.completed:
                return "Завершен";
            case OrderStatusType.canceled:
                return "Отменен";
            default:
                return "-";
        }
    }


    return (
        <Collapse activeKey={active} onChange={() => setActive(active => active === 0 ? 1 : 0)}>
            <Panel showArrow={false} key={'1'} header={
                <Order>
                            <span className="left">
                                <Space size={16}>
                                    <h2>Заказ № {Id}</h2>
                                    <span className={"status"}>{getStatusName(Status)}</span>
                                </Space>
                                <span className="date">{moment(UpdatedDate).format("DD.MM.YYYY")}</span>
                            </span>
                    <span className="rightWrap">
                        <div className="right">
                        <span className="price">{Positions?.every(el=>el.Item?.NewPrice === 0) ? "Под заказ" : formatter.format(Positions?.reduce((previousValue, currentValue) => {
                            return previousValue + currentValue.Count * currentValue.Item.NewPrice
                        }, 0))}</span>
                        <span className="count">{Positions?.length} шт</span>
                    </div>
                                    <span className={"status"}>{getStatusName(Status)}</span>
                   </span>
                    <Arrow45SVG className={`arrow ${active === 0 ? "" : "active"}`}/>
                </Order>
            }>
                <CollapseBody>
                    {Positions?.map(el => <OrderItem key={el.Id}>
                            <span className="title">
                                {el.Item.Name}
                            </span>
                        <div className="right">
                                <span className="price">
                                {el.Item.NewPrice ? formatter.format(el.Count * el.Item.NewPrice) : "Под заказ"}
                            </span>
                            <div className="count">
                                {el.Item.NewPrice ? <>{el.Count}шт
                                    * {formatter.format(el.Item.NewPrice)}</> : <>{el.Count}шт</>}
                            </div>
                        </div>
                    </OrderItem>)}
                </CollapseBody>
            </Panel>
        </Collapse>
    )
}

function MyOrders() {
    const [filter, setFilter] = useState<OrderStatusType | "">("");
    const [selectedYears, setSelectedYears] = useState<string[]>([]);

    const orders: OrderType[] = useSelector((state: any) => state.pageReducer.orders);

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        store.dispatch(getOrdersAction())
    }, [])
    useEffect(() => {
        if (searchParams.get("modal") === "true") {
            setModalOpen(true)
        }
    }, [searchParams])

    useEffect(() => {
        setSelectedYears(_.uniq(orders?.filter(el => filter !== "" ? (el.Status === filter) : true)?.map((el) => moment(el.CreatedDate).format("YYYY"))))
    }, [orders])

    return (
        <StyledMyOrders>
            <SimpleModal open={modalOpen} onCancel={() => {
                setModalOpen(false)
                const newSearchParams = searchParams;
                newSearchParams.delete("modal")
                setSearchParams(newSearchParams);
            }}>
                Ваш заказ успешно оформлен, статус заказа можете отслеживать в личном кабинете
            </SimpleModal>
            <Top>
                <h1>Мои заказы</h1>

                <TopRight>
                    <StyledSelect value={filter} onChange={(value: any) => setFilter(value)}>
                        <Option value={""}>Все</Option>
                        <Option value={OrderStatusType.inWork}>В работе</Option>
                        <Option value={OrderStatusType.completed}>Выполненные</Option>
                        <Option value={OrderStatusType.canceled}>Отмененные</Option>
                    </StyledSelect>
                    <Tags>
                        {_.uniq(orders?.filter(el => filter !== "" ? (el.Status === filter) : true)?.map((el) => moment(el.CreatedDate).format("YYYY")))?.map(el =>
                            <Tag className={selectedYears.includes(el) ? "selected" : ""} onClick={() => {
                                if (selectedYears.includes(el))
                                    setSelectedYears(selectedYears => selectedYears.filter(year => year !== el))
                                else
                                    setSelectedYears(selectedYears => [...selectedYears, el])
                            }} key={el}>{el} г.</Tag>)}
                    </Tags>
                </TopRight>
            </Top>
            <Content>
                {orders?.filter(el => selectedYears.includes(moment(el.CreatedDate).format("YYYY")))?.filter(el => filter !== "" ? (el.Status === filter) : true)?.map(el =>
                    <CollapseItem
                        key={el.Id} {...el}/>)}
            </Content>
        </StyledMyOrders>
    );
}

export default MyOrders;