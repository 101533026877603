import axios from "axios";
import {api} from "../../config";
import {
    GET_CATALOG,
    GET_CONFIG_SUCCESS,
    GET_GROUPS_SUCCESS,
    GET_ORDERS,
    GET_SUB_GROUPS_SUCCESS,
    GET_USERS,
    GET_VIDEOS
} from "../actionTypes";
import {message} from "antd";
import {SortingOrderType} from "../../utils/types";
import PQueue from "p-queue";

export function getGroupsAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/groups?full=true`).then(({data}) => {
                dispatch({type: GET_GROUPS_SUCCESS, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getConfigAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/config`).then(({data}) => {
                dispatch({type: GET_CONFIG_SUCCESS, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getSubGroupsAction(Id) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/groups/sub/${Id}`).then(({data}) => {
                dispatch({type: GET_SUB_GROUPS_SUCCESS, payload: {Id: Id, data: data}})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getUsersAction(Page = 1, searchValue, sortValue, sortOrder, signal) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            let url = `${api}/users`;

            axios(url, {
                signal: signal,
                params: {
                    search: searchValue ? searchValue : undefined,
                    page: Page,
                    count: 4,
                    order: sortValue ? `${sortOrder === SortingOrderType.desc ? "!" : ""}${sortValue}` : undefined
                }
            }).then(({data}) => {
                dispatch({type: GET_USERS, payload: data})
                res();
            }).catch(({response, ...error}) => {
                if (error.code !== "ERR_CANCELED")
                    message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function createGroupAction(fields, Files = [], changeFiles = () => {
}) {
    return (dispatch) => {


        const queue = new PQueue({concurrency: 1});

        return new Promise((res, rej) => {
            if (Files.length <= 0) {
                axios.post(`${api}/groups`, fields).then(({data}) => {
                    dispatch(getGroupsAction())
                    message.success("Новая группа успешно создана!")
                    res();
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                    rej();
                })

            } else {
                let promises = [];

                axios.post(`${api}/groups`, fields).then(({data}) => {
                    let Id = data.Id;

                    Files.forEach(file => {
                        const bodyFormData = new FormData();
                        bodyFormData.append('file', file.file);

                        promises = [...promises, () => axios.post(`${api}/groups/${Id}/images`, bodyFormData, {
                            onUploadProgress: progressEvent => {
                                let progress = (progressEvent.loaded / progressEvent.total) * 100;
                                changeFiles(state => [...state.filter(el => el.id !== file.id), {
                                    ...file, progress: progress
                                }]);
                            }
                        }).then(({data}) => {
                            return data.Id
                        })]

                    })

                    queue.addAll(promises).then(() => {
                        dispatch(getGroupsAction())
                        message.success("Новая группа успешно создана!")
                        res();
                    }).catch(({response}) => {
                        message.error(response?.data || "Error")
                        rej();
                    })
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                    rej();
                })


                // Promise.all(promises).then((values) => {
                //     axios.post(`${api}/user/projects/${Id}/questions/${QuestionId}/answer`, {
                //         Message: Message, AttachedFilesId: values
                //     }).then(() => {
                //         dispatch(getProjectQuestionsAction(Id)).then(() => {
                //             res();
                //         })
                //     }).catch(({response}) => {
                //         message.error(response?.data || "Error")
                //         rej();
                //     })
                // })
            }
        })
    }
}

export function editConfigAction(fields) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/config`, fields).then(({data}) => {
                dispatch(getConfigAction())
                message.success("Настройки успешно сохранены!")
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function removeItemAction(Id) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.delete(`${api}/items/${Id}`).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function removeGroupImageAction(Id, Path) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.delete(`${api}/groups/${Id}/${Path}`).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function removeVideoAction(Id) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.delete(`${api}/videos/${Id}`).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function removeUserAction(Id) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.delete(`${api}/users/${Id}`).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function createUserAction(fields) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/users`, fields).then(({data}) => {
                message.success("Пользователь успешно создан!");
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function addVideoAction(fields, showMessage = true) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/videos`, fields).then(({data}) => {
                showMessage && message.success("Видео успешно добавлено!");
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function editVideoAction(Id, fields, showMessage = true) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/videos/${Id}`, fields).then(({data}) => {
                showMessage && message.warning("Видео успешно изменено!");
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function editItemAction(Id, fields, showMessage = true) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/items/${Id}`, fields).then(({data}) => {
                showMessage && message.warning("Товар успешно изменен!");
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getVideosAction(page, searchValue, sortValue, sortOrder, signal) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/videos`, {
                signal: signal,
                params: {
                    search: searchValue ? searchValue : undefined,
                    page: page,
                    count: 4,
                    order: sortValue ? `${sortOrder === SortingOrderType.desc ? "!" : ""}${sortValue}` : undefined
                }
            }).then(({data}) => {
                dispatch({type: GET_VIDEOS, payload: data})
                res();
            }).catch(({response, ...error}) => {
                if (error.code !== "ERR_CANCELED")
                    message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getOrdersAction(page, selectedStatus, search = "", signal) {
    return (dispatch) => {
        return new Promise((res, rej) => {

            axios.get(`${api}/orders${selectedStatus !== undefined ? `/status/${selectedStatus}` : ""}`, {
                signal: signal,
                params: {
                    search: search ? search : undefined,
                    page: page,
                    count: 9
                }
            }).then(({data}) => {
                dispatch({type: GET_ORDERS, payload: data})
                res();
            }).catch(({response, error}) => {
                if (error.code !== "ERR_CANCELED")
                    message.error(response?.data || "Error")
                rej();
            })

        })
    }
}

export function editOrderAction(Id, fields) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/orders/${Id}`, fields).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function editUserAction(Id, fields) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/users/${Id}`, fields).then(({data}) => {
                message.warning("Пользователь успешно изменен!");
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function sendFeedbackAction(text, files) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            const formData = new FormData();
            formData.append('text', text);
            files.forEach((file, index) => formData.append(`file[${index}]`, file.originFileObj));

            axios.post(`${api}/feedback`, formData).then(({data}) => {
                message.warning("Обращение успешно отправлено!");
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}


export function addImageAction(Id, Image) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            const bodyFormData = new FormData();
            bodyFormData.append('file', Image);
            axios.post(`${api}/items/${Id}/images`, bodyFormData).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function addGroupImageAction(Id, Image) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            const bodyFormData = new FormData();
            bodyFormData.append('file', Image);

            axios.post(`${api}/groups/${Id}/images`, bodyFormData).then(() => {
                dispatch(getGroupsAction())
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function removeImageAction(Id, Image) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.delete(`${api}/items/${Id}/${Image}`).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function createItemAction(fields, Files, changeFiles = () => {
}) {
    return (dispatch) => {
        const queue = new PQueue({concurrency: 1});

        return new Promise((res, rej) => {
            if (Files.length <= 0) {
                axios.post(`${api}/items`, fields).then(({data}) => {
                    message.success("Товар успешно создан!")
                    res();
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                    rej();
                })
            } else {
                let promises = [];

                axios.post(`${api}/items`, fields).then(({data}) => {
                    let Id = data.Id;

                    Files.forEach(file => {
                        const bodyFormData = new FormData();
                        bodyFormData.append('file', file.file);

                        promises = [...promises, () => axios.post(`${api}/items/${Id}/images`, bodyFormData, {
                            onUploadProgress: progressEvent => {
                                let progress = (progressEvent.loaded / progressEvent.total) * 100;
                                changeFiles(state => [...state.filter(el => el.id !== file.id), {
                                    ...file, progress: progress
                                }]);
                            }
                        }).then(({data}) => {
                            return data.Id
                        })]

                    })

                    queue.addAll(promises).then(() => {
                        message.success("Товар успешно создан!")
                        res();
                    }).catch(({response}) => {
                        message.error(response?.data || "Error")
                        rej();
                    })
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                    rej();
                })


                // Promise.all(promises).then((values) => {
                //     axios.post(`${api}/user/projects/${Id}/questions/${QuestionId}/answer`, {
                //         Message: Message, AttachedFilesId: values
                //     }).then(() => {
                //         dispatch(getProjectQuestionsAction(Id)).then(() => {
                //             res();
                //         })
                //     }).catch(({response}) => {
                //         message.error(response?.data || "Error")
                //         rej();
                //     })
                // })
            }
        })
    }
}

export function editGroupAction(Id, fields, sub = false) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/groups/${Id}`, {...fields, ChildGroups: undefined}).then(({data}) => {
                dispatch(getGroupsAction()).then(() => {
                    res();
                })
                // message.warning(`${sub ? "Подгруппа" : "Группа"} успешно изменена!`)
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function changeUserStateAction(login, state) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/users/${login}/state/${state}`).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function createSubGroupAction(Id, fields, file = null) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/groups`, {...fields, ParentGroupId: Id}).then(({data}) => {
                // dispatch(getSubGroupsAction(Id))
                // message.success("Новая подгруппа успешно создана!")

                if (file === null) {
                    res();
                } else {
                    const bodyFormData = new FormData();
                    bodyFormData.append('file', file);
                    axios.post(`${api}/groups/${data.Id}/images`, bodyFormData).then(() => {
                        res();
                    }).catch(({response}) => {
                        message.error(response?.data || "Error")
                        rej();
                    })

                }
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function deleteGroupAction(deleteId, parentId, deepDelete = false) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.delete(`${api}/groups/${deleteId}`, {
                params: {
                    force: deepDelete
                }
            }).then(({data}) => {
                dispatch(getGroupsAction())
                parentId !== undefined && dispatch(getSubGroupsAction(parentId))
                // message.warning("Группа успешно удалена!")
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getCatalogAction(Page = 1, search, sort, sortOrder) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/items?count=4&page=${Page}&all=true`, {
                params: {
                    search: search ? search : undefined,
                    order: sort ? `${sortOrder === SortingOrderType.desc ? "!" : ""}${sort}` : undefined
                }
            }).then(({data}) => {
                dispatch({type: GET_CATALOG, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

