import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import no_img from "../images/no_img.png";
import {move} from "../utils/move";
import ArrowDownSVG from "../icons/ArrowDownSVG";
// @ts-ignore
import InnerImageZoom from 'react-inner-image-zoom'
import {useMediaQuery} from "usehooks-ts";
import {mobileSizeRaw} from "../config";

export const StyledImageViewer = styled.div`
    display: flex;
    gap: 20px;

    img {
        max-width: 470px;
        object-fit: contain;
        overflow: hidden;
    }
`;

const AdditionalPhotos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    object-fit: contain;
`;

const SmallPhoto = styled.img`
    width: 94px;
    height: 94px;
    object-fit: contain;
    border: 1px solid #D7D8D3;
    cursor: pointer;

    &.selected {
        border: 1px solid #5a7ac7;
    }
`;

const SmallPhotoWrap = styled.div`
    display: flex;
    position: relative;

    &.opacity {
        ${SmallPhoto} {
            opacity: .7;
        }
    }

    ${SmallPhoto} {
        &:hover {
            opacity: 1;
        }
    }

    .nextArrow, .prevArrow {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F5F5;
        transform: translate(-50%, 50%);
        width: 52px;
        height: 52px;
        left: 50%;
        bottom: 0;
        cursor: pointer;

        &.prevArrow {
            top: -50%;
        }

        svg {
            scale: 1.5;

            path {
                stroke: #000000;
            }
        }
    }
`;

function ImageViewer({item}: any) {

    const [nextPhoto, setNextPhoto] = useState<boolean>(false);
    const [selectedPhoto, setSelectedPhoto] = useState<any>("");
    const mobileSize = useMediaQuery(`(${mobileSizeRaw})`)

    const formatedItem = useMemo(() => {
        return item.ImageMain === undefined ? {
            ...item,
            ImageUrls: ((item.ImageUrls || []).length <= 0) ? item.ImageUrl !== "" ? [item.ImageUrl] : [] : item.ImageUrls,
            ImageMain: 0
        } : item
    }, [item]);

    useEffect(() => {
        setSelectedPhoto("");
    }, [formatedItem]);

    return (
        <StyledImageViewer>
            {formatedItem.ImageUrls?.[formatedItem?.ImageMain] ?
                <InnerImageZoom hasSpacer={true} zoomPreload={true} zoomScale={3} width={470}
                                height={470}
                                src={`${selectedPhoto ? `/${selectedPhoto?.replace("//", "/")}` : (formatedItem.ImageUrls?.[formatedItem?.ImageMain] ? `/${formatedItem.ImageUrls?.[formatedItem?.ImageMain]?.replace("//", "/")}` : no_img)}`}/> :
                <img src={no_img} alt=""/>}
            {/*<MainPhoto style={{*/}
            {/*    backgroundImage: `url(${selectedPhoto ? `/${selectedPhoto}` : `/${item.ImageUrls?.[0]}`})`,*/}
            {/*    backgroundPosition: backgroundPosition*/}
            {/*}} onMouseMove={(e: any) => {*/}
            {/*    const {left, top, width, height} = e.target.getBoundingClientRect()*/}
            {/*    const x = (e.pageX - left) / width * 100*/}
            {/*    const y = (e.pageY - top) / height * 100*/}
            {/*    setBackgroundPosition({backgroundPosition: `${x}% ${y}%`})*/}
            {/*}}>*/}
            {/*    <img src={`${selectedPhoto ? `/${selectedPhoto}` : `/${item.ImageUrls?.[0]}`}`} alt=""/>*/}
            {/*</MainPhoto>*/}
            <AdditionalPhotos style={{display: ((formatedItem?.ImageUrls || []).length > 0) ? "flex" : "none"}}>
                {move([...formatedItem?.ImageUrls || []], formatedItem?.ImageMain, 0)?.slice(nextPhoto ? (mobileSize ? 2 : 1) : 0, nextPhoto ? undefined : (mobileSize ? 3 : 4))?.map((image, index) =>
                    <SmallPhotoWrap
                        className={nextPhoto && index === 0 ? "opacity" : !nextPhoto && index === 3 ? "opacity" : ""}
                        key={index}>
                        <SmallPhoto
                            className={selectedPhoto === image ? "selected" : ""}
                            onClick={() => {
                                setSelectedPhoto((selectedPhoto: any) => selectedPhoto === image ? "" : image)
                            }}
                            src={`/${image}`}/>
                        {nextPhoto && index === 0 && <span className={"prevArrow"} onClick={() => {
                            setNextPhoto(false)
                        }}>
                                        <ArrowDownSVG style={{transform: "rotate(180deg)"}}/>
                                    </span>}

                        {!nextPhoto && index === (mobileSize ? 2 : 3) &&
                            <span className={"nextArrow"} onClick={() => {
                                setNextPhoto(true)
                            }}>
                                        <ArrowDownSVG/>
                                    </span>}
                    </SmallPhotoWrap>)}
            </AdditionalPhotos>
            {/*<AdditionalPhotos>*/}
            {/*    {move([...item?.ImageUrls || []], item?.ImageMain, 0)?.slice(nextPhoto ? 2 : 0, nextPhoto ? undefined : 3)?.map((image, index) =>*/}
            {/*        <SmallPhotoWrap*/}
            {/*            className={nextPhoto && index === 0 ? "opacity" : !nextPhoto && index === 3 ? "opacity" : ""}*/}
            {/*            key={index}>*/}
            {/*            <SmallPhoto*/}
            {/*                className={selectedPhoto === image ? "selected" : ""}*/}
            {/*                onClick={() => {*/}
            {/*                    setSelectedPhoto((selectedPhoto: any) => selectedPhoto === image ? "" : image)*/}
            {/*                }}*/}
            {/*                src={`/${image}`}/>*/}
            {/*            {nextPhoto && index === 0 && <span className={"prevArrow"} onClick={() => {*/}
            {/*                setNextPhoto(false)*/}
            {/*            }}>*/}
            {/*            <ArrowDownSVG style={{transform: "rotate(180deg)"}}/>*/}
            {/*        </span>}*/}

            {/*            {!nextPhoto && index === 2 && <span className={"nextArrow"} onClick={() => {*/}
            {/*                setNextPhoto(true)*/}
            {/*            }}>*/}
            {/*            <ArrowDownSVG/>*/}
            {/*        </span>}*/}
            {/*        </SmallPhotoWrap>)}*/}
            {/*</AdditionalPhotos>*/}

        </StyledImageViewer>
    );
}

export default ImageViewer;