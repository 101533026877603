import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import styled, {StyledComponent} from "styled-components";
import {
    Checkbox,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Progress, Radio,
    Select,
    Space,
    Spin,
    Table,
    TableProps,
    Upload
} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {StyledButton} from "../../components/StyledButton";
import {StyledCheckbox} from "../../components/StyledCheckbox";
import {useSelector} from "react-redux";
import store from "../../redux/store";
import {
    addImageAction,
    createItemAction,
    editItemAction,
    getCatalogAction,
    getGroupsAction,
    getSubGroupsAction, removeImageAction,
    removeItemAction
} from "../../redux/actions/adminActions";
import {GroupType, PaginationType, ProductSortType, ProductType, SortingOrderType} from "../../utils/types";
import TrashSVG from "../../icons/TrashSVG";
import moment from "moment";
import UploadPlusSVG from "../../icons/UploadPlusSVG";
import ArrowsSVG from "../../icons/ArrowsSVG";
import {RefTable} from "antd/es/table/interface";
import ForwardTable from "antd/es/table/Table";
import {CompoundedComponent} from "antd/es/float-button/interface";
import {RcFile} from "antd/es/upload";
import SimpleModal from "../../components/SimpleModal";
import _ from "lodash";
import {move} from "../../utils/move";

const {Option} = Select;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #202020;

  span {
    margin-top: 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #898989;
  }
`;

const PhotoBlank = styled.div`
  width: 72px;
  height: 72px;
  min-width: 72px;
  min-height: 72px;
  object-fit: contain;
  margin-right: 16px;
  background: #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: gray;
  user-select: none;
`;

const Photo = styled.img`
  width: 72px;
  height: 72px;
  object-fit: contain;
  margin-right: 16px;
  user-select: none;
  pointer-events: none;
`;

const StyledTable = styled(Table)`
  max-width: 1200px;
  width: 100%;

  .ant-table {
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    margin-bottom: 150px;

    table {
      .ant-table-thead {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 96%;
          transform: translate(-50%, 0);
          left: 50%;
          border-bottom: 1px solid #DCDCDC;
          z-index: 99;
        }

        tr {
          th {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            color: #898989;
            background: #fff;
            border: none;

            &::before {
              display: none;
            }
          }
        }
      }

      .ant-table-tbody {
        tr:nth-child(even) {
          background: #F9F9F9;
        }
      }
    }
  }
`;

const StyledInputSearch = styled(Input)`
  max-width: 230px;
  width: 100%;
  height: 40px;

  border: 1px solid #DCDCDC;
  border-radius: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #898989;
`;

const AddMediaButton = styled(StyledButton)`
  margin-left: auto;
`;

const Top = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 35px;

  .sortOrder {
    cursor: pointer;
    user-select: none;
  }
`;

const StyledSelect = styled(Select)`
  max-width: 230px;
  width: 100%;
  text-align: center;

  margin-left: 16px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #898989;

  .ant-select-selection-item {
    opacity: 0.7;
  }

  .ant-select-selector {

    border: 1px solid #DCDCDC !important;
    border-radius: 8px;
    height: 41px !important;
    align-items: center;

  }
`;

const TableWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  position: relative;

  .ant-btn {
    position: absolute;
    bottom: 100px;
    right: 0;
  }
`;

const DeleteTitle = styled.div`
  display: flex;
  align-items: center;

  .ant-checkbox {
    margin-right: 8px;
    top: -1px;
  }
`;

const StyledCatalogPage = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 45px;

  .ant-modal {
    width: 600px;
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
  }

  label {
    &:not(.ant-radio-wrapper) {
      display: flex;
    }

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    align-items: center;

    color: #000000;
  }

  .ant-form {
    .add_char {
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;

      display: flex;
      align-items: flex-end;
      text-decoration-line: underline;

      color: #70B6C1;
    }

    *::placeholder {

      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;

      color: #898989;
    }
  }

  .removeModalWrap {
    top: 30%;
  }

  .ant-modal-wrap {
    display: flex;
    margin: 10px 0;

    .ant-modal {
      padding-bottom: 0;
      top: 0;
    }

    .removeModal {
      width: auto !important;

      .ant-modal-content {
        padding: 40px 40px 30px 40px;
      }

      h1 {
        margin-bottom: 25px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        color: #898989;
      }
    }
  }

  .ant-upload-list {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .sorting {
    .ant-select-item {
      .ant-select-item-option-content {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #898989;

        opacity: 0.7;
      }
    }
  }

`;

const FilePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const UploadWrap = styled.div`
  display: flex;
`;

const ChangeMainTip = styled.div`
  display: flex;
  align-items: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  max-width: 180px;

  .ant-checkbox {
    margin-right: 10px;
    margin-left: 18px;
  }

  color: #898989;
`;
const AttachedFile = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 25px;
  flex-wrap: wrap;

  .ant-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const UploadZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;
  width: 72px;
  height: 72px;
  border: 1px solid #DCDCDC;
  border-radius: 8px;
  position: relative;

  span {
    font-weight: 400;
    font-size: 14px;

    color: #898989;
    position: absolute;
    bottom: -25px;
  }
`;

const FileWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;


const File = styled.div`
  display: flex;
  width: 72px;
  height: 72px;
  position: relative;
  border: 1px solid #DCDCDC;
  border-radius: 8px;
  justify-content: center;

  .remote_remove {
    opacity: 0;
    position: absolute;
    transition: all .2s ease-in-out;
    top: 50%;
    color: #ffffff;
    left: 50%;
    font-size: 40px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    user-select: none;
    z-index: 99;
  }

  .remove {
    opacity: 0;
    position: absolute;
    transition: all .2s ease-in-out;
    top: 50%;
    color: #ffffff;
    left: 50%;
    font-size: 40px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    user-select: none;
    z-index: 99;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    border-radius: 8px;
    transition: all .2s ease-in-out;
  }

  &:hover {
    &::after {
      opacity: .6;
    }

    .remove, .remote_remove {
      opacity: 1;
    }
  }
`;


function CatalogPage() {
    const catalog: PaginationType<ProductType[]> = useSelector((state: any) => state.adminReducer.catalog);
    const groups: GroupType[] = useSelector((store: any) => store.adminReducer.groups)
    const subGroups: GroupType[][] = useSelector((store: any) => store.adminReducer.subGroups)
    const [page, setPage] = useState<number>(1);

    const [editId, setEditId] = useState<number | null>(null);

    const [deleteIds, setDeleteIds] = useState<number[]>([]);

    const [createFormRef] = Form.useForm();
    const [editFormRef] = Form.useForm();

    const [createVisible, setCreateVisible] = useState<boolean>(false);

    const [editVisible, setEditVisible] = useState<boolean>(false);

    const [selectedGroup, setSelectedGroup] = useState<number | undefined>(undefined);
    const [selectedSubGroup, setSelectedSubGroup] = useState<number | undefined>(undefined);

    const [attachedFiles, setAttachedFiles]: any = useState<any>([]);

    const [searchValue, setSearchValue] = useState<string>("");

    const [sortValue, setSortValue] = useState<ProductSortType | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<SortingOrderType>(SortingOrderType.asc);

    useEffect(() => {
        if (!editVisible && !createVisible) {
            setSelectedGroup(undefined)
            setSelectedSubGroup(undefined)
        }
    }, [editVisible, createVisible])

    useEffect(() => {
        if (editId !== null) {
            setEditVisible(true);
            const item = catalog.Data.find(el => el.Id === editId)!;
            setSelectedGroup(item?.GroupId)

            const flattenItems = (items: any, key: any) => {
                return items.reduce((flattenedItems: any, item: any) => {
                    flattenedItems.push(item)
                    if (Array.isArray(item[key])) {
                        flattenedItems = flattenedItems.concat(flattenItems(item[key], key))
                    }
                    return flattenedItems
                }, [])
            }

            const group1: GroupType | undefined = _.find(flattenItems(groups, 'ChildGroups'), ['Id', item.GroupId]);
            // console.log("group1", group1);
            const group2: GroupType | undefined = _.find(flattenItems(groups, 'ChildGroups'), ['Id', group1?.ParentGroupId]);
            // console.log("group2", group2);
            const group3: GroupType | undefined = _.find(flattenItems(groups, 'ChildGroups'), ['Id', group2?.ParentGroupId]);
            // console.log("group3", group3);

            setSelectedGroup((group3 ? group3 : group2 ? group2 : group1)?.Id)
            setSelectedSubGroup((group3 ? group2 : group2 ? group1 : undefined)?.Id)

            editFormRef.setFieldsValue({
                ...item,
                GroupId: (group3 ? group3 : group2 ? group2 : group1)?.Id,
                SubGroupId: (group3 ? group2 : group2 ? group1 : undefined)?.Id,
                SubSubGroupId: group3 ? group1?.Id : undefined,
                Meta: item?.Meta?.Characteristics || []
            })
        } else {
            setEditVisible(false);
        }
    }, [editId, groups])

    useEffect(() => {
        if (!editVisible) {
            setEditId(null);
        }

    }, [editVisible])

    useEffect(() => {
        store.dispatch(getCatalogAction(page, searchValue, sortValue, sortOrder));
        store.dispatch(getGroupsAction());
    }, [])


    const columns: ColumnsType<ProductType> = [
        {
            title: 'Фото',
            dataIndex: 'ImageUrls',
            key: 'ImageUrls',
            render: (text, record) => text?.length ? <Photo src={"/" + text[record?.ImageMain]} alt={"ava"}/> :
                <PhotoBlank>?</PhotoBlank>,
        },
        {
            title: 'Название',
            dataIndex: 'Name',
            key: 'Name',
            width: 200,
            render: (text, record) => <span className={"clickable"}>{text}</span>,
            onCell: (data, index) => {
                return {
                    onClick: () => {
                        setEditId(data.Id);
                    }
                }
            }
        },
        {
            title: 'Код товара',
            dataIndex: 'Code',
            key: 'Code'
        },
        {
            title: 'Цена',
            dataIndex: 'NewPrice',
            key: 'NewPrice',
            render: (text, record) => <Price>
                {text}₽
                <span>
                    {record.OldPrice}₽
                </span>
            </Price>,
        },
        {
            title: 'Количество',
            dataIndex: 'Count',
            key: 'Count'
        },
        {
            title: 'Популярный',
            dataIndex: 'Popular',
            key: 'Popular',
            render: (_, record) => {
                return (editLoading ? <Spin/> :
                        <StyledCheckbox checked={_} onClick={() => {
                            setEditLoading(true)
                            store.dispatch(editItemAction(record.Id, {...record, Popular: !_}, false)).then(() => {
                                store.dispatch(getCatalogAction(page, searchValue, sortValue, sortOrder));
                            }).finally(() => {
                                setEditLoading(false);
                            })
                        }}/>
                )
            },
        },
        {
            title: 'Хит',
            dataIndex: 'Hot',
            key: 'Hot',
            render: (_, record) => {
                return (editLoading ? <Spin/> :
                        <StyledCheckbox checked={_} onClick={() => {
                            setEditLoading(true)
                            store.dispatch(editItemAction(record.Id, {...record, Hot: !_}, false)).then(() => {
                                store.dispatch(getCatalogAction(page, searchValue, sortValue, sortOrder));
                            }).finally(() => {
                                setEditLoading(false);
                            })
                        }}/>
                )
            },
        },
        {
            title: 'Видимость',
            dataIndex: 'Visibility',
            key: 'Visibility',
            render: (_, record) => {
                return (editLoading ? <Spin/> :
                        <StyledCheckbox checked={_} onClick={() => {
                            setEditLoading(true)
                            store.dispatch(editItemAction(record.Id, {...record, Visibility: !_}, false)).then(() => {
                                store.dispatch(getCatalogAction(page, searchValue, sortValue, sortOrder));
                            }).finally(() => {
                                setEditLoading(false);
                            })
                        }}/>
                )
            },
        },
        {
            title: <DeleteTitle><StyledCheckbox
                checked={catalog?.Data?.map(el => el.Id)?.every(el => deleteIds.includes(el))}
                onClick={({target: {checked}}: any) => {
                    if (checked)
                        setDeleteIds(deleteIds => [...deleteIds, ...catalog?.Data.map(el => el.Id)])
                    else
                        setDeleteIds(deleteIds => deleteIds.filter(el => !catalog?.Data.map(el => el.Id)?.includes(el)))
                }}/> Удалить</DeleteTitle>,
            render: (_, record) => <StyledCheckbox checked={deleteIds.indexOf(record.Id) !== -1}
                                                   onClick={({target: {checked}}: any) => setDeleteIds(values => {
                                                       if (checked)
                                                           return [...values, record.Id]
                                                       else
                                                           return values.filter(el => el !== record.Id)
                                                   })}/>,
        },
    ];

    const handleGetCatalog = (Page: number) => {
        // console.log(Page);
        setPage(Page);
    }

    useEffect(() => {
        store.dispatch(getCatalogAction(page, searchValue, sortValue, sortOrder));
    }, [page, searchValue, sortValue, sortOrder])

    const handleDelete = () => {
        let promises: any = [];
        deleteIds.map(el => promises = [...promises, store.dispatch(removeItemAction(el))])
        Promise.all(promises).then(() => {
            message.warning(deleteIds.length > 1 ? "Предметы успешно удалены!" : "Предмет успешно удален!")
            store.dispatch(getCatalogAction(page, searchValue, sortValue, sortOrder))
            setDeleteIds([]);
            setRemovedAFew(deleteIds.length > 1)
            setDeleteConfirmOpen(false);
            setDeleteSuccessful(true);
        }).catch(() => {
            store.dispatch(getCatalogAction(page, searchValue, sortValue, sortOrder))
        })
    }

    useEffect(() => {

        createFormRef.resetFields(["SubGroupId"])

        if (selectedGroup !== undefined)
            store.dispatch(getSubGroupsAction(selectedGroup));
    }, [selectedGroup])


    const [loading, setLoading] = useState<boolean>(false);
    const [imageMain, setImageMain] = useState<number>(0);
    const [editLoading, setEditLoading] = useState<boolean>(false);

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
    const [deleteSuccessful, setDeleteSuccessful] = useState<boolean>(false);
    const [removedAFew, setRemovedAFew] = useState<boolean>(false);

    const find = (id: number, array: GroupType[]): GroupType | undefined => {
        let result;
        array.some(o => o.Id === id && (result = o) || (result = find(id, o.ChildGroups || [])));
        return result;
    }

    useEffect(() => {
        // console.log(find(1120, groups));
    }, [groups])

    return (
        <StyledCatalogPage>
            <SimpleModal open={deleteSuccessful} onCancel={() => setDeleteSuccessful(false)}>
                {removedAFew ? "Товары успешно удалены!" : "Товар успешно удален!"}
            </SimpleModal>
            <SimpleModal onCancel={() => setDeleteConfirmOpen(false)} closable={false} wrapClassName={"removeModalWrap"}
                         open={deleteConfirmOpen} className={"removeModal"}
                         getContainer={() => document.querySelector(`.${StyledCatalogPage.styledComponentId}`)!}>
                <h1>{deleteIds.length > 1 ? "Выбранные товары будут удалены" : "Выбранный товар будет удален"}</h1>
                <Space size={14}>
                    <StyledButton onClick={handleDelete}>Подтвердить</StyledButton>
                    <StyledButton onClick={() => setDeleteConfirmOpen(false)}
                                  className={"cancel"}>Отменить</StyledButton>
                </Space>
            </SimpleModal>
            <Modal width={620} forceRender open={createVisible}
                   onCancel={() => {
                       if (!loading) {
                           setCreateVisible(false)
                           setAttachedFiles([]);
                           createFormRef.resetFields()
                       }
                   }}
                   onOk={() => {
                       if (!loading)
                           createFormRef.submit()
                   }}
                   getContainer={() => document.querySelector(`.${StyledCatalogPage.styledComponentId}`)!}>
                <Form
                    initialValues={{ByOrder: false}}
                    disabled={loading} form={createFormRef} layout={"vertical"}
                    onFinish={(fields) => {
                        // console.log({...fields, Meta: {Characteristics: fields.Meta}})

                        setLoading(true);
                        store.dispatch(createItemAction({
                            ...fields,
                            SubSubGroupId: undefined,
                            SubGroupId: undefined,
                            GroupId: fields.SubSubGroupId || fields.SubGroupId || fields.GroupId,
                            Meta: {Characteristics: fields.Meta},
                            ImageMain: imageMain
                        }, attachedFiles, setAttachedFiles)).then(() => {
                            store.dispatch(getCatalogAction(catalog.Page, searchValue, sortValue, sortOrder))
                            setLoading(false);
                            setCreateVisible(false);
                            createFormRef.resetFields();
                            setImageMain(0);
                            setAttachedFiles([]);
                        }).catch(() => {
                            setLoading(false);
                        })
                    }}>
                    <Form.Item rules={[{
                        required: true,
                        message: "Обязательное поле!"
                    }]} name={"Name"} label={"Название товара:"}>
                        <Input placeholder={"Hikmicro M30 - ручная тепловизионная камера"}/>
                    </Form.Item>
                    <Form.Item name={"ShortDesc"} label={"Краткое описание товара:"}>
                        <Input.TextArea
                            placeholder={"Hikmicro M30 - ручная тепловизионная камера, Hikmicro M30 - ручная тепловизионная камера "}/>
                    </Form.Item>
                    <Form.Item name={"FullDesc"} label={"Полное описание товара:"}>
                        <Input.TextArea
                            placeholder={"Hikmicro M30 - ручная тепловизионная камера, Hikmicro M30 - ручная тепловизионная камера Hikmicro M30 - ручная тепловизионная камера, Hikmicro M30 - ручная тепловизионная камера  M30 - ручная тепловизионная камера, Hikmicro M30 - ручная тепловизионная камера "}/>
                    </Form.Item>
                    <Form.Item>
                        <UploadWrap>
                            <Upload
                                accept={"image/png, image/jpeg"}
                                disabled={attachedFiles.length >= 5}
                                showUploadList={false}
                                beforeUpload={(file: RcFile) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                    if (!isJpgOrPng) {
                                        message.error('Вы можете загружать только файл JPG/PNG!');
                                    }
                                    const isLt2M = file.size / 1024 / 1024 < 10;
                                    if (!isLt2M) {
                                        message.error('Изображение должно быть меньше 10MB!');
                                    }
                                    return isJpgOrPng && isLt2M;
                                }}
                                customRequest={(_: any) => {
                                    const reader = new FileReader();
                                    reader.onloadend = function () {
                                        setAttachedFiles([...attachedFiles, {
                                            id: uuidv4(),
                                            file: _.file,
                                            raw: reader.result,
                                            time: moment()
                                        }])
                                    }
                                    if (_.file) {
                                        reader.readAsDataURL(_.file);
                                    }
                                }}>
                                <UploadZone>
                                    <UploadPlusSVG/>
                                    <span>Фото</span>
                                </UploadZone>
                            </Upload>
                            <AttachedFile className={attachedFiles.length ? "" : "hidden"}>
                                {attachedFiles?.sort((file1: any, file2: any) => {
                                    if (file1?.time > file2?.time) {
                                        return 1;
                                    }
                                    if (file1?.time < file2?.time) {
                                        return -1;
                                    }
                                    return 0;
                                })?.map((file: any, index: any) =>
                                        <FileWrap key={index}>
                                            <File>
                            <span className={"fileWrap"}>
                                {file.progress ? <Progress type={"circle"} percent={file.progress} width={50}
                                                           format={(percent) => percent === 100 ? "Успех" : <span
                                                               className={"percentage"}>{percent?.toFixed(2)}%</span>}/> :
                                    <FilePreview src={file.raw} alt=""/>}
                            </span>
                                                <span className={"remove"} onClick={() => {
                                                    if (index === imageMain) {
                                                        setImageMain(0)
                                                    }
                                                    setAttachedFiles(attachedFiles.filter((_: any, _index: any) => _index !== index))
                                                }}>
                                                x
                                            </span>
                                            </File>
                                            <StyledCheckbox
                                                checked={index === imageMain}
                                                onClick={() => {
                                                    setImageMain(index)
                                                }}/>
                                        </FileWrap>
                                )}
                            </AttachedFile>
                            <ChangeMainTip>
                                <StyledCheckbox checked={true}/>
                                <span>Выберите главную
                                фотографию</span>
                            </ChangeMainTip>
                        </UploadWrap>
                    </Form.Item>
                    <Form.Item>
                        <Form.Item rules={[{
                            required: true,
                            message: "Обязательное поле!"
                        }, {
                            pattern: /^\d{1,10}$/,
                            message: "Не более 10 символов."
                        }]} name={"NewPrice"} label={"Новая цена:"}
                                   style={{display: 'inline-block', width: 'calc(25% - 6px)', marginRight: '8px'}}>
                            <InputNumber placeholder={"2 990"} addonAfter={"₽"}/>
                        </Form.Item>
                        <Form.Item rules={[{
                            pattern: /^\d{1,10}$/,
                            message: "Не более 10 символов."
                        }]} name={"OldPrice"} label={"Старая цена:"}
                                   style={{display: 'inline-block', width: 'calc(25% - 6px)', marginRight: '8px'}}>
                            <InputNumber placeholder={"2 990"} addonAfter={"₽"}/>
                        </Form.Item>
                        <Form.Item name={"Code"} label={"Код товара:"}
                                   style={{display: 'inline-block', width: 'calc(25% - 6px)', marginRight: '8px'}}>
                            <Input placeholder={"8545523225"}/>
                        </Form.Item>
                        <Form.Item rules={[{
                            required: true,
                            message: "Обязательное поле!"
                        }]} name={"Count"} label={"Количество:"}
                                   style={{display: 'inline-block', width: 'calc(25% - 6px)'}}>
                            <InputNumber placeholder={"10 589"}/>
                        </Form.Item>
                    </Form.Item>
                    {/*<Form.Item name={"ByOrder"} label={"Под заказ"} valuePropName={"checked"}>*/}
                    {/*    <StyledCheckbox/>*/}
                    {/*</Form.Item>*/}
                    <Space style={{display: 'flex', justifyContent: "space-between"}}>
                        <Form.Item label={"Количество товара:"}>
                            <Radio.Group>
                                <Radio value={1}>Мало</Radio>
                                <Radio value={2}>Средне</Radio>
                                <Radio value={3}>Много</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name={"Priority"} label={"Приоритет вывода в группе:"}>
                            <Radio.Group>
                                <Radio value={100}>Высокий</Radio>
                                <Radio value={50}>Средний</Radio>
                                <Radio value={0}>Низкий</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Space>
                    <Form.Item rules={[{
                        required: true,
                        message: "Обязательное поле!"
                    }]} name={"GroupId"} label={"Группа:"}>
                        <Select onSelect={(value) => setSelectedGroup(value)} value={selectedGroup}
                                placeholder={"Геодезическое оборудование"}>
                            {groups?.map(group => <Option key={group.Id} value={group.Id}>{group.Name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"SubGroupId"} label={"Подгруппа:"}>
                        <Select allowClear onSelect={(value) => setSelectedSubGroup(value)} value={selectedSubGroup}
                                placeholder={"Нивелиры"}>
                            {selectedGroup && groups?.find(group => group.Id === selectedGroup)?.ChildGroups?.map(group =>
                                <Option key={group.Id}
                                        value={group.Id}>{group.Name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"SubSubGroupId"} label={"Подгруппа 1:"}>
                        <Select allowClear placeholder={"Нивелиры электронные"}>
                            {selectedGroup && groups?.find(group => group.Id === selectedGroup)?.ChildGroups?.find(group => group.Id === selectedSubGroup)?.ChildGroups?.map(group =>
                                <Option key={group.Id}
                                        value={group.Id}>{group.Name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.List name={"Meta"}>
                        {(fields, {add, remove}) => (
                            <>
                                {fields?.map(({key, name, ...restField}) => <Form.Item style={{margin: 0}} key={key}
                                                                                       label={key === 0 && "Характеристика товара:"}>
                                    <Form.Item
                                        rules={[{
                                            required: true,
                                            message: "Обязательное поле!"
                                        }]}
                                        {...restField} name={[name, "Name"]}
                                        style={{display: 'inline-block', width: 'calc(50% - 28px)'}}>
                                        <Input placeholder={"Элементы питания"}/>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{
                                            required: true,
                                            message: "Обязательное поле!"
                                        }]}
                                        {...restField} name={[name, "Value"]}
                                        style={{
                                            display: 'inline-block',
                                            width: 'calc(50% - 48px)',
                                            marginLeft: 28
                                        }}>
                                        <Input placeholder={"Аккумулятор"}/>
                                    </Form.Item>
                                    <Form.Item style={{
                                        display: 'inline-block',
                                        width: '20px',
                                        marginLeft: 28
                                    }}>
                                        <TrashSVG onClick={() => remove(name)}/>
                                    </Form.Item>
                                </Form.Item>)}
                                <Form.Item>
                                    <span onClick={() => add()} className={"add_char"}>+Добавить характеристику</span>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
            <Modal width={620} forceRender open={editVisible}
                   onCancel={() => {
                       if (!loading)
                           setEditVisible(false)
                   }}
                   onOk={() => {
                       if (!loading)
                           editFormRef.submit()
                   }}
                   getContainer={() => document.querySelector(`.${StyledCatalogPage.styledComponentId}`)!}>
                <Form
                    initialValues={{ByOrder: false}}
                    disabled={loading} form={editFormRef} layout={"vertical"}
                    onFinish={(fields) => {
                        setLoading(true);
                        store.dispatch(editItemAction(editId, {
                            ...catalog.Data?.find(el => el.Id === editId),
                            ...fields,
                            SubSubGroupId: undefined,
                            SubGroupId: undefined,
                            GroupId: fields.SubSubGroupId || fields.SubGroupId || fields.GroupId,
                            Meta: {Characteristics: fields.Meta}
                        })).then(() => {
                            store.dispatch(getCatalogAction(catalog.Page, searchValue, sortValue, sortOrder))
                            setLoading(false);
                            editFormRef.resetFields();
                            setEditId(null);
                        }).catch(() => {
                            setLoading(false);
                        })
                    }}>
                    <Form.Item rules={[{
                        required: true,
                        message: "Обязательное поле!"
                    }]} name={"Name"} label={"Название товара:"}>
                        <Input placeholder={"Hikmicro M30 - ручная тепловизионная камера"}/>
                    </Form.Item>
                    <Form.Item name={"ShortDesc"} label={"Краткое описание товара:"}>
                        <Input.TextArea
                            placeholder={"Hikmicro M30 - ручная тепловизионная камера, Hikmicro M30 - ручная тепловизионная камера "}/>
                    </Form.Item>
                    <Form.Item name={"FullDesc"} label={"Полное описание товара:"}>
                        <Input.TextArea
                            placeholder={"Hikmicro M30 - ручная тепловизионная камера, Hikmicro M30 - ручная тепловизионная камера Hikmicro M30 - ручная тепловизионная камера, Hikmicro M30 - ручная тепловизионная камера  M30 - ручная тепловизионная камера, Hikmicro M30 - ручная тепловизионная камера "}/>
                    </Form.Item>
                    <Form.Item>
                        <UploadWrap>
                            <Upload
                                accept={"image/png, image/jpeg"}
                                disabled={loading || ((catalog?.Data?.find(el => el.Id === editId)?.ImageUrls?.length || 0) >= 5)}
                                showUploadList={false}
                                beforeUpload={(file: RcFile) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                    if (!isJpgOrPng) {
                                        message.error('Вы можете загружать только файл JPG/PNG!');
                                    }
                                    const isLt2M = file.size / 1024 / 1024 < 10;
                                    if (!isLt2M) {
                                        message.error('Изображение должно быть меньше 10MB!');
                                    }
                                    return isJpgOrPng && isLt2M;
                                }}
                                customRequest={(_: any) => {
                                    message.warning("Загрузка...")
                                    setLoading(true)
                                    store.dispatch(addImageAction(editId, _.file)).then(() => {
                                        message.success("Фото успешно загружено!")
                                        store.dispatch(getCatalogAction(page, searchValue, sortValue, sortOrder)).then(() => {
                                            setLoading(false);
                                        });
                                    })
                                }}>
                                <UploadZone>
                                    <UploadPlusSVG/>
                                    <span>Фото</span>
                                </UploadZone>
                            </Upload>
                            <AttachedFile>
                                {catalog?.Data?.find(el => el.Id === editId)?.ImageUrls?.map((photo, index, array) =>
                                    <FileWrap key={index}>
                                        <File>
                                        <span className={"fileWrap"}>
                                            <FilePreview src={`/${photo?.replace("//", "/")}`} alt=""/>
                                        </span>
                                            <span className={"remote_remove"} onClick={() => {
                                                store.dispatch(removeImageAction(editId, photo)).then(() => {
                                                    message.warning("Фото удалено!")
                                                    store.dispatch(editItemAction(editId, {
                                                        ...catalog.Data?.find(el => el.Id === editId),
                                                        ImageMain: 0
                                                    })).then(() => {
                                                        store.dispatch(getCatalogAction(catalog.Page, searchValue, sortValue, sortOrder))
                                                        setLoading(false);
                                                    }).catch(() => {
                                                        setLoading(false);
                                                    })
                                                })
                                            }}>
                                                x
                                            </span>
                                        </File>
                                        <StyledCheckbox
                                            checked={catalog.Data?.find(el => el.Id === editId)?.ImageMain === index}
                                            onClick={() => {
                                                setLoading(true);
                                                store.dispatch(editItemAction(editId, {
                                                    ...catalog.Data?.find(el => el.Id === editId),
                                                    ImageMain: index
                                                })).then(() => {
                                                    store.dispatch(getCatalogAction(catalog.Page, searchValue, sortValue, sortOrder))
                                                    setLoading(false);
                                                }).catch(() => {
                                                    setLoading(false);
                                                })

                                            }}/>
                                    </FileWrap>
                                )}
                            </AttachedFile>
                            <ChangeMainTip>
                                <StyledCheckbox checked={true}/>
                                <span>Выберите главную
                                фотографию</span>
                            </ChangeMainTip>
                        </UploadWrap>
                    </Form.Item>
                    <Form.Item>
                        <Form.Item rules={[{
                            required: true,
                            message: "Обязательное поле!"
                        }, {
                            pattern: /^\d{1,10}$/,
                            message: "Не более 10 символов."
                        }]} name={"NewPrice"} label={"Новая цена:"}
                                   style={{display: 'inline-block', width: 'calc(25% - 6px)', marginRight: '8px'}}>
                            <InputNumber placeholder={"2 990"} addonAfter={"₽"}/>
                        </Form.Item>
                        <Form.Item rules={[{
                            pattern: /^\d{1,10}$/,
                            message: "Не более 10 символов."
                        }]} name={"OldPrice"} label={"Старая цена:"}
                                   style={{display: 'inline-block', width: 'calc(25% - 6px)', marginRight: '8px'}}>
                            <InputNumber placeholder={"2 990"} addonAfter={"₽"}/>
                        </Form.Item>
                        <Form.Item name={"Code"} label={"Код товара:"}
                                   style={{display: 'inline-block', width: 'calc(25% - 6px)', marginRight: '8px'}}>
                            <Input placeholder={"8545523225"}/>
                        </Form.Item>
                        <Form.Item rules={[{
                            required: true,
                            message: "Обязательное поле!"
                        }]} name={"Count"} label={"Количество:"}
                                   style={{display: 'inline-block', width: 'calc(25% - 6px)'}}>
                            <InputNumber placeholder={"10 589"}/>
                        </Form.Item>
                    </Form.Item>
                    {/*<Form.Item name={"ByOrder"} label={"Под заказ"} valuePropName={"checked"}>*/}
                    {/*    <Checkbox/>*/}
                    {/*</Form.Item>*/}
                    <Space style={{display: 'flex', justifyContent: "space-between"}}>
                        <Form.Item label={"Количество товара:"}>
                            <Radio.Group>
                                <Radio value={1}>Мало</Radio>
                                <Radio value={2}>Средне</Radio>
                                <Radio value={3}>Много</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name={"Priority"} label={"Приоритет вывода в группе:"}>
                            <Radio.Group>
                                <Radio value={100}>Высокий</Radio>
                                <Radio value={50}>Средний</Radio>
                                <Radio value={0}>Низкий</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Space>
                    <Form.Item rules={[{
                        required: true,
                        message: "Обязательное поле!"
                    }]} name={"GroupId"} label={"Группа:"}>
                        <Select onSelect={(value) => setSelectedGroup(value)} value={selectedGroup}
                                placeholder={"Геодезическое оборудование"}>
                            {groups?.map(group => <Option key={group.Id} value={group.Id}>{group.Name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"SubGroupId"} label={"Подгруппа:"}>
                        <Select allowClear onSelect={(value) => setSelectedSubGroup(value)} value={selectedSubGroup}
                                placeholder={"Нивелиры"}>
                            {selectedGroup && groups?.find(group => group.Id === selectedGroup)?.ChildGroups?.map(group =>
                                <Option key={group.Id}
                                        value={group.Id}>{group.Name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"SubSubGroupId"} label={"Подгруппа 1:"}>
                        <Select allowClear placeholder={"Нивелиры электронные"}>
                            {selectedGroup && groups?.find(group => group.Id === selectedGroup)?.ChildGroups?.find(group => group.Id === selectedSubGroup)?.ChildGroups?.map(group =>
                                <Option key={group.Id}
                                        value={group.Id}>{group.Name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.List name={"Meta"}>
                        {(fields, {add, remove}) => (
                            <>
                                {fields?.map(({key, name, ...restField}) => <Form.Item style={{margin: 0}} key={key}
                                                                                       label={key === 0 && "Характеристика товара:"}>
                                    <Form.Item
                                        rules={[{
                                            required: true,
                                            message: "Обязательное поле!"
                                        }]}
                                        {...restField} name={[name, "Name"]}
                                        style={{display: 'inline-block', width: 'calc(50% - 28px)'}}>
                                        <Input placeholder={"Элементы питания"}/>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{
                                            required: true,
                                            message: "Обязательное поле!"
                                        }]}
                                        {...restField} name={[name, "Value"]}
                                        style={{
                                            display: 'inline-block',
                                            width: 'calc(50% - 48px)',
                                            marginLeft: 28
                                        }}>
                                        <Input placeholder={"Аккумулятор"}/>
                                    </Form.Item>
                                    <Form.Item style={{
                                        display: 'inline-block',
                                        width: '20px',
                                        marginLeft: 28
                                    }}>
                                        <TrashSVG onClick={() => remove(name)}/>
                                    </Form.Item>
                                </Form.Item>)}
                                <Form.Item>
                                    <span onClick={() => add()} className={"add_char"}>+Добавить характеристику</span>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
            <Top>
                <StyledInputSearch value={searchValue} placeholder={"Поиск товара"} onChange={({target: {value}}) => {
                    setSearchValue(value);
                }}/>
                <Space size={30}>
                    <StyledSelect popupClassName={"sorting"}
                                  value={sortValue}
                                  onChange={(value: any) => {
                                      setSortValue(value);
                                  }}
                                  allowClear
                                  getPopupContainer={() => document.querySelector(`.${StyledCatalogPage.styledComponentId}`)!}
                                  placeholder={"Порядок по умолчанию"}>
                        <Option value={ProductSortType.name}>По имени</Option>
                        <Option value={ProductSortType.code}>По коду</Option>
                        <Option value={ProductSortType.date}>По дате</Option>
                    </StyledSelect>
                    <ArrowsSVG className={"sortOrder"} value={sortOrder}
                               onClick={() => setSortOrder(sortOrder => sortOrder === SortingOrderType.asc ? SortingOrderType.desc : SortingOrderType.asc)}/>
                </Space>
                <AddMediaButton onClick={() => setCreateVisible(true)}>+Добавить товар</AddMediaButton>
            </Top>
            <TableWrap>
                <StyledTable<(props: TableProps<ProductType>) => JSX.Element>
                    columns={columns}
                    dataSource={catalog.Data}
                    rowKey={'Id'}
                    pagination={{
                        current: catalog.Page,
                        total: catalog.Total,
                        pageSize: catalog.Count,
                        onChange: handleGetCatalog
                    }}/>
                <StyledButton disabled={deleteIds.length <= 0}
                              onClick={() => setDeleteConfirmOpen(true)}>Удалить {deleteIds.length > 1 ? "товары" : "товар"}</StyledButton>
            </TableWrap>
        </StyledCatalogPage>
    );
}

export default CatalogPage;