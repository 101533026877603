import React from 'react';
import styled from "styled-components";
import bannerBottom from "../images/bannerBottom.svg";
import banner4 from "../images/banner4.png";
import {Button} from "antd";
import {Link} from "react-router-dom";
import {mobileSizeRaw} from "../config";

const StyledMainBanner = styled.div`
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
    min-width: 1200px;
    background: #F2F2F4;
    position: relative;
    height: 400px;
    overflow: hidden;

    .ant-btn {
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        border: 1px solid #5B77CB;
        padding: 17px 28px;
        height: auto;
        background: transparent;
        border-radius: 4px;
        margin-top: 32px;
        margin-bottom: 24px;
        min-width: 163px;

        &:hover {
            background: #5B77CB;
            border: 1px solid #5B77CB;

            span {
                color: #ffffff;
            }
        }

        span {
            color: #5B77CB;
        }
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 110px;
        z-index: 9;
    }

    img {
        width: 878px;
        object-fit: contain;
        position: absolute;
        right: -150px;
        top: -70px;
        
    }

    h2 {
        font-weight: 500;
        font-size: 17.852px;
        line-height: 22px;

        color: #5B77CB;
    }

    h1 {
        width: 448px;
        text-align: start;
        font-weight: 600;
        font-size: 32px;
        line-height: 98.8%;

        color: #000000;

        span {
            color: #5b77cb;
        }
    }

    h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #000000;
    }

    @media screen and (max-width: 999px) {
        justify-content: flex-start;
        padding-top: 70px;
        height: 620px;
        min-width: 480px;

        .left {
            margin-left: 20px;
        }

        img {
            width: 550px;
            object-fit: contain;
            position: absolute;
            right: -70px;
            bottom: -50px;
            top: auto;
        }
    }
    @media screen and (${mobileSizeRaw}) {
        min-width: 360px;
        .left {
        }

        h1 {
            width: 248px;
        }

        img {
            width: 380px;
            object-fit: contain;
            position: absolute;
            right: -70px;
            bottom: -40px;
        }
    }
`;


function Banner3() {
    return (
        <StyledMainBanner>
            <span className="left">
                <h1>Входной контроль ОТК поставляемого инструмента, собственная лаборатория, <span>короткие сроки</span> поверки и калибровки!</h1>
                <Link to={"/catalog"}>
                    <Button>
                        В каталог
                    </Button>
                </Link>
            </span>
            <img src={banner4} alt=""/>
        </StyledMainBanner>
    );
}

export default Banner3;