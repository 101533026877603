import React from 'react';
import styled from "styled-components";
import banner3 from "../images/banner3.png";
import {mobileSizeRaw} from "../config";

const StyledMainBanner = styled.div`
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
    min-width: 1200px;
    background: #F2F2F4;
    position: relative;
    height: 400px;
    overflow: hidden;

    .ant-btn {
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        border: 1px solid #5B77CB;
        padding: 17px 28px;
        height: auto;
        background: transparent;
        border-radius: 4px;
        margin-top: 32px;
        margin-bottom: 24px;
        min-width: 163px;

        &:hover {
            background: #5B77CB;
            border: 1px solid #5B77CB;

            span {
                color: #ffffff;
            }
        }

        span {
            color: #5B77CB;
        }
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 110px;
        z-index: 9;
    }

    img {
        width: 631px;
        object-fit: contain;
        position: absolute;
        right: 0;
    }

    h2 {
        font-weight: 500;
        font-size: 17.852px;
        line-height: 22px;

        color: #5B77CB;
    }

    h1 {
        width: 458px;
        text-align: start;
        font-weight: 400;
        font-size: 32px;
        line-height: 120%;

        color: #000000;

        strong {
            font-weight: 600;
        }

        span {
            color: #5b77cb;
        }
    }

    h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #000000;
    }

    @media screen and (max-width: 999px) {
        justify-content: flex-start;
        padding-top: 70px;
        height: 620px;
        min-width: 480px;

        .left {
            margin-left: 20px;
            margin-top: 160px;
        }

        h1 {
            padding-right: 10px;
        }

        img {
            width: 440px;
            object-fit: contain;
            position: absolute;
            top: 0;
        }
    }
    @media screen and (${mobileSizeRaw}) {
        min-width: 360px;
        .left {
            margin-top: 70px;
        }

        h1 {
            width: 340px;
        }

        img {
            width: 320px;
            object-fit: contain;
            position: absolute;
            top: 0;
        }
    }
`;


function MainBanner() {
    return (
        <StyledMainBanner>
            <span className="left">
                <h1>
                    <strong><span>Изготовление</span> по чертежам и <br/> образцам заказчика:</strong>
                <br/>-Инструмент
                <br/>-Калибры
                <br/>-ЖД шаблоны и многое другое.
                <br/>-Разработка чертежей 2D, 3D.
                <br/>-Собственная лаборатория контроля
                </h1>
            </span>
            <img src={banner3} alt=""/>
        </StyledMainBanner>
    );
}

export default MainBanner;